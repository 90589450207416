import { THEME } from 'constants/index'
import { oneOf } from 'utils/lib'

interface IProps {
    error?: boolean
    disabled?: boolean
}

export default function CheckboxChecked({ error, disabled }: IProps) {
    const color = oneOf(
        [
            [Boolean(error), THEME.color.error_main],
            [Boolean(disabled), THEME.color.grey_500],
        ],
        THEME.color.primary_800,
    )

    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1"
                y="1"
                width="18"
                height="18"
                rx="1"
                fill={color}
                stroke={color}
                strokeWidth="2"
            />
            <path
                d="M4 8.42857L9.07407 14L16 5"
                stroke="white"
                strokeWidth="3"
            />
        </svg>
    )
}
