import TextField, { TextFieldProps } from '@mui/material/TextField'
import { forwardRef } from 'react'

export default forwardRef(function Input(props: TextFieldProps, ref: any) {
    return (
        <TextField
            size="small"
            ref={ref}
            {...props}
            sx={{
                '> label.MuiInputLabel-root': { top: '4px' },
                '> div.MuiInputBase-root': {
                    height: 44,
                    bgcolor: 'grey.100',
                    '> fieldset': {
                        borderRadius: '5px',
                        borderColor: 'grey.300',
                    },
                    '> input.MuiInputBase-input': {
                        bgcolor: 'transparent',
                        fontWeight: 400,
                    },
                    '&.MuiInputBase-multiline': {
                        height: 'initial',
                    },
                },
                '> div.MuiInputBase-root.Mui-focused': {
                    bgcolor: 'bg.paper',
                    '> fieldset ': {
                        border: '1px solid',
                        borderColor: 'grey.900',
                        bgcolor: 'transparent',
                    },
                },
                '> div.MuiInputBase-root.Mui-focused.Mui-error': {
                    '> fieldset ': {
                        borderColor: 'error.main',
                    },
                },
                '> div.Mui-error > input': {
                    color: 'error.main',
                },
                ...props.sx,
            }}
        />
    )
})
