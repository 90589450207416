import { RadioGroup as MuiRadioGroup, Radio as MuiRadio } from '@mui/material'
import { RadioGroupProps } from '@mui/material/RadioGroup/RadioGroup'
import { RadioProps } from '@mui/material/Radio/Radio'
import { ReactComponent as RadioIcon } from 'assets/images/icons/radio.svg'
import { ReactComponent as RadioCheckedIcon } from 'assets/images/icons/radio-checked.svg'

export function RadioGroup({ sx = {}, ...props }: RadioGroupProps) {
    return (
        <MuiRadioGroup
            {...props}
            sx={{
                '& > label': { mr: '80px' },
                '& .MuiTypography-root': {
                    fontWeight: 400,
                    fontSize: '16px',
                    color: 'grey.700',
                },
                '& .MuiRadio-root.Mui-checked + .MuiTypography-root': {
                    fontWeight: 'bold',
                    color: 'primary.800',
                },
                ...sx,
            }}
        />
    )
}

export function Radio({ sx = {}, ...props }: RadioProps) {
    return (
        <MuiRadio
            {...props}
            sx={{
                padding: '0 14px 0',
                '&:hover': { bgcolor: 'transparent' },
                '.MuiTouchRipple-root > span': {
                    display: 'none',
                },
                ...sx,
            }}
            icon={<RadioIcon />}
            checkedIcon={<RadioCheckedIcon />}
        />
    )
}
