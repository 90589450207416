import { FormControl, FormControlLabel } from '@mui/material'
import Checkbox from './Checkbox'
import { append, filter, includes } from 'utils/lib'

interface OptionItem {
    label: string
    value: string
}

interface IProps {
    options: OptionItem[]
    value: string[]
    setValue: (arr: string[]) => void
    disabled?: boolean
    sx?: any
}

export default function CheckboxGroup({
    options,
    value,
    setValue,
    disabled,
    sx = {},
}: IProps) {
    return (
        <FormControl
            sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                '>.MuiFormControlLabel-root': {
                    margin: 0,
                    width: 150,
                    padding: '0 5px',
                    flexGrow: 'unset',
                    '>.MuiCheckbox-root': {
                        mr: '14px',
                        '&.Mui-checked + .MuiTypography-root': {
                            fontWeight: 700,
                            color: 'primary.800',
                            '&.Mui-disabled': {
                                color: 'grey.500',
                            },
                        },
                    },
                },
                ...sx,
            }}
        >
            {options.map(({ label, value: val }) => (
                <FormControlLabel
                    disabled={disabled}
                    value={val}
                    key={val}
                    control={
                        <Checkbox
                            disabled={disabled}
                            checked={includes(val, value)}
                            onClick={(e: any) => {
                                if (value.includes(e.target.value)) {
                                    setValue(
                                        filter(
                                            item => item !== e.target.value,
                                            value,
                                        ),
                                    )
                                } else {
                                    setValue(append(e.target.value, value))
                                }
                            }}
                        />
                    }
                    label={label}
                />
            ))}
        </FormControl>
    )
}
