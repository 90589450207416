import { Avatar, Box, Stack, Typography } from '@mui/material'
import ClearIcon from './svgs/ClearIcon'
import AddIcon from '@mui/icons-material/Add'
import { useState } from 'react'
import { delay } from 'utils/lib'
import toast from 'biz/toast'

interface IProps {
    s3Url?: string
    previewImage?: boolean
    file?: any
    setFile?: any
    readOnly?: boolean
    removeClick?: any
    label?: string
}
export default function FileUpload({
    previewImage = false,
    s3Url,
    file,
    setFile,
    readOnly,
    removeClick,
    label = '파일 업로드',
}: IProps) {
    const empty = !file && !s3Url
    return (
        <Box
            sx={{
                display: 'flex',
                height: 70,
                width: 1,
                bgcolor: 'grey.50',
                color: 'grey.500',
                border: '1px solid',
                borderColor: 'grey.300',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            {!empty ? (
                previewImage ? (
                    <ImagePreview
                        src={s3Url ?? (file ? URL.createObjectURL(file) : '')}
                        readOnly={readOnly}
                        removeClick={() => {
                            setFile()
                            removeClick?.()
                        }}
                    />
                ) : (
                    <FilePreview
                        src={s3Url || file.name}
                        readOnly={readOnly}
                        removeClick={() => {
                            setFile()
                            removeClick?.()
                        }}
                    />
                )
            ) : (
                <UploadControl
                    setFile={setFile}
                    label={label}
                    readOnly={readOnly}
                />
            )}
        </Box>
    )
}

function ImagePreview({ src, readOnly, removeClick }) {
    const [sx, setSx] = useState({})
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            <Avatar
                alt="business logo"
                variant="square"
                sx={{
                    width: 60,
                    height: 60,
                    transition: '.5s',
                    bgcolor: 'transparent',
                    ...sx,
                }}
                src={src}
            />
            <ClearIcon
                style={{
                    display: readOnly ? 'none' : 'initial',
                    position: 'absolute',
                    top: '0',
                    left: '70px',
                    cursor: 'pointer',
                }}
                onClick={async () => {
                    setSx({ transform: 'scaleY(0)' })
                    await delay(500)
                    setSx({ transform: 'none' })
                    removeClick?.()
                }}
            />
        </Box>
    )
}

function FilePreview({ src, readOnly, removeClick }) {
    return (
        <Box
            sx={{
                width: '100%',
                px: '16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            }}
        >
            <Typography sx={{ fontWeight: 'bold', color: 'grey.700' }}>
                {src}
            </Typography>
            <ClearIcon
                style={{
                    display: readOnly ? 'none' : 'initial',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    removeClick?.()
                }}
            />
        </Box>
    )
}

function UploadControl({ readOnly, setFile, label }) {
    return (
        <label
            htmlFor="contained-button-file"
            style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                cursor: readOnly ? 'not-allowed' : 'pointer',
            }}
        >
            {!readOnly && (
                <input
                    style={{ display: 'none' }}
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={e => {
                        const file = e.target.files![0]
                        if (file!.size > 1024 * 1024 * 10) {
                            toast.error('10MB 이상 파일은 업로드할 수 없습니다')
                            return
                        }
                        setFile(e.target.files?.[0])
                    }}
                />
            )}
            <Stack direction="row" spacing="10px" alignItems="center">
                <AddIcon sx={{ fontSize: '25px', cursor: 'pointer' }} />
                <Box
                    sx={{
                        color: 'grey.500',
                        fontSize: 16,
                        fontWeight: 700,
                        height: 25,
                        paddingTop: '5px',
                    }}
                >
                    {label}
                </Box>
            </Stack>
        </label>
    )
}
