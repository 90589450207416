import { ReactNode, useEffect, useRef, useState } from 'react'
import { useTheme } from '@mui/system'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material'
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography'
import { OverridableStringUnion } from '@mui/types'
import { Variant } from '@mui/material/styles/createTypography'
import { useSelector } from 'react-redux'
import { THEME } from 'constants/index'
import throttle from 'lodash.throttle'
import reducer from 'store/reducer'

type RootState = ReturnType<typeof reducer>

interface IProps {
    border?: boolean
    boxShadow?: boolean
    children: ReactNode
    content?: boolean
    contentClass?: string
    contentSX?: any
    headerSX?: any
    titleVariant?: OverridableStringUnion<
        Variant | 'inherit',
        TypographyPropsVariantOverrides
    >
    titleDivider?: boolean
    secondary?: ReactNode | string | any
    shadow?: string
    sx?: any
    title?: ReactNode | string | any
    headerBar?: ReactNode | string
    headerBarOnScroll?: boolean
    headerAtScrollText?: string
    footer?: ReactNode
}

export default function ScrollCard({
    border = false,
    boxShadow,
    children,
    content = true,
    contentClass = '',
    contentSX = {},
    titleVariant = 'h1',
    titleDivider = false,
    headerSX = {},
    secondary,
    shadow,
    sx = {},
    title,
    headerBar,
    headerBarOnScroll = false,
    headerAtScrollText,
    footer,
    ...others
}: IProps) {
    const customization = useSelector((state: RootState) => state.customization)
    const theme = useTheme()
    const [scrolled, setScrolled] = useState(false)
    const divRef = useRef<any>(null)

    useEffect(() => {
        const scrollHandler = throttle(event => {
            setScrolled(event.target.scrollTop > 0)
        }, 100)

        divRef.current?.addEventListener('scroll', scrollHandler)
        return () =>
            divRef.current?.removeEventListener('scroll', scrollHandler)
    }, [])

    return (
        <Card
            ref={divRef}
            {...others}
            sx={{
                border: border ? '1px solid' : 'none',
                borderColor: theme.palette.primary[200] + 75,
                borderRadius: THEME.borderRadius2,
                ':hover': {
                    boxShadow: boxShadow
                        ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)'
                        : 'inherit',
                },
                overflow: 'auto',
                height: 'calc(100vh - 129px)',
                position: 'relative',
                ...sx,
            }}
        >
            {(!headerBarOnScroll || (headerBarOnScroll && scrolled)) && (
                <Box
                    component="header"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: `${customization.borderRadius * 2}px ${
                            customization.borderRadius * 2
                        }px 0 0`,
                        position: 'fixed',
                        width: {
                            xs: 'calc(100% - 52px)',
                            sm: 'calc(100% - 71px)',
                            md: 'calc(100% - 80px)',
                            lg: `calc(100% - ${
                                customization.opened ? 320 : 80
                            }px)`,
                            xl: `calc(100% - ${
                                customization.opened ? 320 : 80
                            }px)`,
                        },
                        zIndex: 2,
                        padding: '0 30px',
                        height: '72px',
                        borderBottom: `1px solid`,
                        borderBottomColor: 'grey.300',
                        bgcolor: 'bg.paper',
                        ...(scrolled && {
                            boxShadow: '0px 11px 14px rgb(0 0 0 / 5%)',
                            borderBottom: 'none',
                        }),
                    }}
                >
                    {headerBar}
                </Box>
            )}
            {!headerBarOnScroll && <Box height="80px" />}
            {title && (
                <CardHeader
                    sx={{
                        visibility:
                            !headerBarOnScroll ||
                            (headerBarOnScroll && !scrolled)
                                ? 'visible'
                                : 'hidden',
                        padding: `80px 60px 20px`,
                        ...headerSX,
                    }}
                    title={
                        typeof title === 'string' ? (
                            <Typography variant={titleVariant}>
                                {title}
                            </Typography>
                        ) : (
                            title
                        )
                    }
                    action={secondary}
                />
            )}

            {titleDivider && <Divider sx={{ margin: '14px 60px 20px' }} />}

            {content && (
                <CardContent
                    sx={{ padding: '20px 60px 100px', ...contentSX }}
                    className={contentClass}
                >
                    {children}
                </CardContent>
            )}
            {!content && children}

            {footer && (
                <Box
                    sx={{
                        position: 'fixed',
                        width: {
                            xs: 'calc(100% - 52px)',
                            sm: 'calc(100% - 71px)',
                            md: 'calc(100% - 80px)',
                            lg: `calc(100% - ${
                                customization.opened ? 320 : 80
                            }px)`,
                            xl: `calc(100% - ${
                                customization.opened ? 320 : 80
                            }px)`,
                        },
                        borderRadius: `0 0 ${
                            customization.borderRadius * 2
                        }px ${customization.borderRadius * 2}px`,
                        zIndex: 2,
                        borderTop: '1px solid',
                        borderColor: 'grey.300',
                        padding: '20px 30px',
                        bottom: '20px',
                        bgcolor: 'bg.paper',
                    }}
                >
                    {footer}
                </Box>
            )}
        </Card>
    )
}
