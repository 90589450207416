import { ListItem, ListItemText } from '@mui/material'

export default function AdAccountListItem({ label, value }) {
    return (
        <ListItem>
            <ListItemText
                sx={{
                    flex: '140px 0 0',
                    '> .MuiTypography-root': {
                        fontWeight: 700,
                        fontSize: '12px',
                        color: 'grey.600',
                    },
                }}
            >
                {label}
            </ListItemText>
            <ListItemText
                sx={{
                    flex: 1,
                    '> .MuiTypography-root': {
                        fontWeight: 700,
                        fontSize: '16px',
                        color: 'grey.700',
                        wordBreak: 'break-all',
                    },
                }}
            >
                {value}
            </ListItemText>
        </ListItem>
    )
}
