import axios from 'axios'
import { omitProto } from 'utils/lib'

const mockData = require(process.env.REACT_APP_MOCK_DATA_PATH ||
    'api/mock-data').default

export default async ({ path, option, headers }) => {
    const $logger = window.$logger.addTags('request')
    const url = path.startsWith('http')
        ? path
        : process.env.REACT_APP_API_SERVER_URL + path
    const input = option.method === 'get' ? option.params : option.data

    if (process.env.REACT_APP_API_MOCK_UP === 'true') {
        const mockKey = url + (input ? ',' + JSON.stringify(input) : '')
        let result =
            mockData[option.method] &&
            (mockData[option.method][mockKey] || mockData[option.method][url])
        if (typeof result === 'string' && result.startsWith('http')) {
            result = await axios.get(result)
            result = result.data
        }
        if (result) {
            $logger.info(
                `[mock][${option.method}] ` + path,
                omitProto({
                    input,
                    output: result,
                }),
            )
            return result
        }
    }

    const result = await axios({
        url,
        ...option,
        headers: { ...headers, ...option.headers },
    })
    $logger.debug(
        `[${option.method}] ` + path,
        omitProto({
            input,
            output: result.data,
        }),
    )
    return result.data
}
