import { useQuery } from 'react-query'
import { ManagerApi } from 'api/index'
import { Manager } from 'model'

export default function useUser({ userId }) {
    return useQuery<Manager>(
        ['user', userId],
        () => ManagerApi.getManager(userId),
        {
            suspense: true,
            enabled: userId > 0,
        },
    )
}
