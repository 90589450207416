import { Button as MuiButton } from '@mui/material'

export default function Button(props) {
    const variant = props.variant ?? 'outlined'
    return (
        <MuiButton
            {...props}
            variant={variant}
            sx={{
                borderRadius: '5px',
                fontWeight: 700,
                lineHeight: '16px',
                boxShadow: 'none',
                '&:hover': {
                    borderColor: 'grey.300',
                    boxShadow:
                        '0px 2px 4px -1px rgba(0,0,0,0.02),0px 4px 5px 0px rgba(0,0,0,0.04),0px 1px 10px 0px rgba(0,0,0,0.12)',
                },

                '&.MuiButton-sizeLarge': {
                    px: '30px',
                    height: 60,
                    fontSize: '16px',
                },
                '&.MuiButton-sizeMedium': {
                    px: '20px',
                    height: 44,
                },
                '&.MuiButton-sizeSmall': {
                    height: 32,
                    px: '10px',
                    // bgcolor: 'grey.50',
                    // border: 'none',
                    minWidth: 'fit-content',
                },

                '&.MuiButton-text': {
                    color: props.color ?? 'grey.700',
                },
                '&.MuiButton-text.Mui-disabled': {
                    color: 'grey.500',
                },

                '&.MuiButton-contained': {
                    color: 'background.paper',
                    bgcolor: props.color ?? 'grey.900',
                },
                '&.MuiButton-contained.Mui-disabled': {
                    bgcolor: 'grey.500',
                },

                '&.MuiButton-outlined': {
                    color: props.color ?? 'grey.600',
                    borderColor: props.color ?? 'grey.300',
                },
                '&.MuiButton-outlined:hover': {
                    borderColor: props.color,
                },
                '&.MuiButton-outlined.Mui-disabled': {
                    color: 'grey.500',
                },

                ...props.sx,
            }}
        />
    )
}
