import { Typography, Grid } from '@mui/material'
import React from 'react'
import { channelCategoryLabel } from 'biz/index'

interface IProps {
    name: string
    img: string
    type: string
}

export default function MediaTitleSection({ name, img, type }: IProps) {
    return (
        <div>
            <Grid container alignItems="center">
                <Grid item>
                    <img
                        style={{
                            width: '54px',
                            height: '54px',
                            objectFit: 'cover',
                            borderRadius: '5px',
                        }}
                        src={img}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="h2" sx={{ pr: '14px', pl: '16px' }}>
                        {name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography
                        sx={{
                            display: 'inline-block',
                            color: 'grey.600',
                        }}
                    >
                        {channelCategoryLabel(type)}
                    </Typography>
                </Grid>
            </Grid>
            <Typography
                sx={{
                    mt: '20px',
                    lineHeight: '21px',
                    color: 'grey.700',
                }}
            >
                {name} 데이터를 연동합니다. 데이터는 리포트, 데이터 추출, 자동화
                기능에 연결됩니다.
            </Typography>
            <Typography
                sx={{
                    fontSize: '12px',
                    lineHeight: '21px',
                    pt: '4px',
                    color: 'grey.500',
                }}
            >
                *토글을 ON 상태로 바꾸면 데이터 수집을 시작합니다. 수집을
                희망하지 않으면 OFF 상태를 유지하거나 변경하세요.
            </Typography>
        </div>
    )
}
