import { TOAST_MESSAGES } from 'constants/messages'
import CopiableText from './CopiableText'
import { Stack } from '@mui/material'
import { apiErrorReport } from 'biz/index'
import Details from './Details'

export default function ErrorMessage({ err, wrapperStyle = {} }) {
    const customMessage = TOAST_MESSAGES.find(item =>
        item.pred(err.response?.data.detail),
    )
    if (customMessage) {
        return customMessage.custom(err.response?.data.detail)
    }

    const errorReport = apiErrorReport(err)

    if (!errorReport) {
        return err.message
    }

    return (
        <div style={wrapperStyle}>
            <CopiableText target={errorReport}>
                <h4 style={{ margin: 0 }}>{err.message}</h4>
            </CopiableText>

            <Stack
                spacing="20px"
                style={{ overflow: 'auto', maxHeight: 'calc(100vh - 110px' }}
            >
                <Details summary="error-details" open>
                    {errorReport}
                </Details>
                <Details summary="err-stack" open>
                    {err.stack}
                </Details>
            </Stack>
        </div>
    )
}
