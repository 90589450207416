import Button from '../ui-component/Button'
import { Typography, Stack } from '@mui/material'
import { always } from 'utils/lib'

const withDesc = message => desc => <WithDesc desc={desc}>{message}</WithDesc>

export const TOAST_MESSAGES = [
    {
        pred: (str: string) => /url already exist/.test(str),
        custom: always('오로라에 이미 등록되어 있는 광고주의 서비스입니다.'),
    },
    {
        pred: (str: string) => /account already exist/.test(str),
        custom: withDesc('이미 등록되어 있는 계정입니다.'),
    },
    {
        pred: (str: string) => /can change only in standby status/.test(str),
        custom: withDesc(
            'standby 상태가 아닌 경우, account_id 는 수정이 불가합니다.',
        ),
    },
    {
        pred: (str: string) =>
            /has not 'master' (or 'member' )?permission of business/.test(str),
        custom: withDesc('처리 권한이 없습니다.'),
    },
    {
        pred: (str: string) => /status already changed in/.test(str),
        custom: withDesc(
            '이미 초대된 사용자입니다. 화면을 새로고침 해주시기 바랍니다.',
        ),
    },
    {
        pred: (str: string) => /status already changed cancel_req/.test(str),
        custom: withDesc(
            '이미 가입요청을 취소한 사용자입니다. 화면을 새로고침 해주시기 바랍니다.',
        ),
    },
    {
        pred: (str: string) => /already exist business name/.test(str),
        custom: withDesc('동일한 이름의 광고주가 이미 등록되어 있습니다.'),
    },
]

export const SCREEN_MESSAGES = [
    {
        pred: (str: string) => /Loading chunk \d+ failed/.test(str),
        title: '일시적인 문제로 파일을 로드하지 못했습니다.',
        desc: error => error.message,
        button: (
            <Button
                sx={{ fontWeight: 700 }}
                onClick={() => window.location.reload()}
            >
                새로고침 하기
            </Button>
        ),
    },
    {
        pred: (str: string) =>
            /manager\(\d+\) has not member in business\(\d+\)/.test(str),
        title: '해당 광고주에 접근 권한이 없습니다.',
        desc: '해당 광고주에 접근 권한이 제한되었습니다. 더 이상 접근할 수 없으므로 권한을 다시 요청해 주세요.',
        button: (
            <Button
                sx={{ fontWeight: 700 }}
                onClick={() => window.location.assign('/basic/businesses')}
            >
                광고주 목록 화면으로 이동
            </Button>
        ),
    },
]

function WithDesc({ desc, children }) {
    return (
        <Stack>
            <span>{children}</span>
            <Typography
                sx={{
                    fontSize: 14,
                    color: 'grey.500',
                    fontStyle: 'italic',
                    marginTop: '5px',
                }}
            >
                {desc}
            </Typography>
        </Stack>
    )
}
