import { forwardRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from '@mui/material/styles'
import {
    Avatar,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { MENU_OPEN, SET_MENU } from 'store/actions'
import config from 'config'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

export default function NavItem({ item, level }) {
    const theme = useTheme() as any
    const dispatch = useDispatch()
    const customization = useSelector((state: any) => state.customization)
    const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

    const Icon = item.icon
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width:
                    customization.isOpen.findIndex(id => id === item?.id) > -1
                        ? 8
                        : 6,
                height:
                    customization.isOpen.findIndex(id => id === item?.id) > -1
                        ? 8
                        : 6,
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    )

    let itemTarget = '_self'
    if (item.target) {
        itemTarget = '_blank'
    }

    let listItemProps = {
        component: forwardRef((props, ref) => (
            <Link
                ref={ref as any}
                {...props}
                to={`${config.basename}${item.url}`}
                target={itemTarget}
            />
        )),
    }
    if (item?.external) {
        listItemProps = {
            component: 'a' as any,
            href: item.url,
            target: itemTarget,
        } as any
    }

    const itemHandler = id => {
        dispatch({ type: MENU_OPEN, id })
        if (matchesSM) {
            dispatch({ type: SET_MENU, opened: false })
        }
    }

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex(id => id === item.id)
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id })
        }
        // eslint-disable-next-line
    }, [])

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `${customization.borderRadius}px`,
                height: level > 1 ? 40 : 54,
                mb: level > 1 ? 0 : '10px',
                backgroundColor:
                    level > 1 ? 'transparent !important' : 'grey.50',
                '&.Mui-selected, &.Mui-selected:hover': {
                    bgcolor: 'primary.main',
                    color: level > 1 ? 'primary.main' : 'bg.paper',
                    '> .MuiListItemIcon-root': {
                        color: level > 1 ? 'primary.main' : 'bg.paper',
                    },
                    '> .MuiListItemText-root > .MuiTypography-root': {
                        fontWeight: 'bold',
                    },
                },

                '&:hover': {
                    bgcolor: 'rgba(33, 150, 243, 0.1)',
                    color: level > 1 ? 'primary.main' : 'grey.700',
                    '> .MuiListItemIcon-root': {
                        color: level > 1 ? 'primary.main' : 'grey.700',
                    },
                },
                '&.Mui-selected svg > g': {
                    stroke: theme.palette.background.paper,
                },
                '&:hover svg > g': {
                    stroke: 'grey.700',
                },
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`,
            }}
            selected={customization.isOpen.findIndex(id => id === item.id) > -1}
            onClick={() => itemHandler(item.id)}
        >
            <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography
                        variant={
                            customization.isOpen.findIndex(
                                id => id === item.id,
                            ) > -1
                                ? 'h5'
                                : 'body1'
                        }
                        color="inherit"
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.subMenuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
            {item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={
                        item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                    }
                />
            )}
        </ListItemButton>
    )
}
