import ChartIcon from 'ui-component/svgs/ChartIcon'
import FilterIcon from 'ui-component/svgs/FilterIcon'
import ToolIcon from 'ui-component/svgs/ToolIcon'
import SettingIcon from 'ui-component/svgs/SettingIcon'
import DocIcon from 'ui-component/svgs/DocIcon'

const menu = {
    id: 'basic-menu',
    title: '기본 관리',
    type: 'group',
    children: [
        ...(process.env.REACT_APP_ENV_TYPE === 'prd'
            ? [
                  {
                      id: 'report',
                      title: '리포트',
                      type: 'item',
                      url: '/basic/report',
                      icon: ChartIcon,
                      breadcrumbs: false,
                  },
                  {
                      id: 'extract-wip',
                      title: '데이터 추출',
                      type: 'item',
                      url: '/basic/extract-wip',
                      icon: FilterIcon,
                      breadcrumbs: false,
                  },
              ]
            : [
                  {
                      id: 'report',
                      title: '리포트',
                      type: 'collapse',
                      icon: ChartIcon,
                      children: [
                          {
                              id: 'total-report',
                              title: '통합 리포트',
                              type: 'item',
                              url: '/basic/report/total-report',
                              breadcrumbs: false,
                          },
                          {
                              id: 'manage-data',
                              title: '수동 데이터 관리',
                              type: 'item',
                              url: '/basic/report/manage-data',
                              breadcrumbs: false,
                          },
                          {
                              id: 'index-rule',
                              title: '인덱스 규칙 관리',
                              type: 'item',
                              url: '/basic/report/index-rule',
                              breadcrumbs: false,
                          },
                          {
                              id: 'customizing-rule',
                              title: '커스터마이징 규칙 관리',
                              type: 'item',
                              url: '/basic/report/customizing-rule',
                              breadcrumbs: false,
                          },
                      ],
                  },
                  {
                      id: 'extract',
                      title: '데이터 추출',
                      type: 'collapse',
                      icon: FilterIcon,
                      children: [
                          {
                              id: 'extract',
                              title: '데이터 추출 목록',
                              type: 'item',
                              url: '/basic/extract',
                              breadcrumbs: false,
                          },
                          {
                              id: 'extract-periodicity',
                              title: '정기성 추출 관리',
                              type: 'item',
                              url: '/basic/extract-periodicity',
                              breadcrumbs: false,
                          },
                          {
                              id: 'update-channel',
                              title: '매체, 트래커 최신화',
                              type: 'item',
                              url: '/basic/channel-refresh',
                              breadcrumbs: false,
                          },
                      ],
                  },
              ]),
        {
            id: 'solution',
            title: '자동화',
            type: 'item',
            url: '/basic/solution',
            icon: ToolIcon,
            breadcrumbs: false,
        },
        {
            id: 'channels',
            title: '매체, 트래커 정보 관리',
            type: 'item',
            url: '/basic/channels',
            icon: DocIcon,
            breadcrumbs: false,
        },
        {
            id: 'setting',
            title: '설정',
            type: 'collapse',
            icon: SettingIcon,
            children: [
                {
                    id: 'business-setting',
                    title: '광고주 정보',
                    type: 'item',
                    url: '/basic/business-setting',
                    breadcrumbs: false,
                },
                {
                    id: 'manage-member',
                    title: '사용자 관리',
                    type: 'item',
                    url: '/basic/manage-member',
                    breadcrumbs: false,
                },
            ],
        },
    ],
}

export default menu
