import * as React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import LinearProgress from '@mui/material/LinearProgress'
import { Z_INDEX } from 'constants/index'

export default function Loading() {
    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                    opacity: '0.7',
                    backgroundColor: '#111',
                    zIndex: Z_INDEX.loading,
                }}
            />
            <div
                style={{
                    width: 300,
                    textAlign: 'center',
                    position: 'fixed',
                    top: '50%',
                    left: '50%',
                    // backgroundColor: '#fff',
                    padding: '2px',
                    WebkitTransform: 'translate(-50%, -50%)',
                    msTransform: 'translate(-50%, -50%)',
                    // MozTransform: 'translate(-50%, -50%)',
                    OTransform: 'translate(-50%, -50%)',
                    transform: 'translate(-50%, -50%)',
                    zIndex: Z_INDEX.loading,
                }}
            >
                <CircularProgress />
                <LinearProgress />
            </div>
        </>
    )
}
