import { Box, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import MediaCard from 'ui-component/cards/MediaCard'
import TitleSection from 'ui-component/TitleSection'
import DialogEditAccount from './DialogEditAccount'
import ScrollCard from 'ui-component/cards/ScrollCard'
import Button from 'ui-component/Button'
import useAdAccounts from 'queries/useAdAccounts'
import useBizId from 'zustands/useBizId'
import { find, go, groupBy, path, propEq } from 'utils/lib'
import { useState } from 'react'
import NoResultMessage from 'ui-component/NoResultMessage'
import { AdAccountSimple } from 'model/ad-account-simple'
import useChannels from 'queries/useChannels'
import { ChannelSimple } from 'model/channel-simple'
import { channelCategoryLabel } from 'biz/index'
import useBusiness from 'queries/useBusiness'
import { BizMgrRole } from 'model/biz-mgr-role'

export default function Channels() {
    const [visible, setVisible] = useState(false)
    const [channel, setChannel] = useState<ChannelSimple | null>(null)
    const { data: channelList } = useChannels()
    const navigate = useNavigate()
    const { bizId } = useBizId()
    const { data } = useAdAccounts<AdAccountSimple>({ bizId })
    const { data: business } = useBusiness({ bizId })
    const adAccounts = data || []
    const accountsByType = groupBy(path(['channel']), adAccounts)
    const channels = Object.entries(accountsByType)

    return (
        <ScrollCard
            headerBar={
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Typography
                        variant="h4"
                        sx={{ color: 'grey.600', pl: '10px' }}
                    >
                        매체, 트래커 정보 관리
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/basic/add-media')}
                        disabled={business?.my_role === BizMgrRole.Viewer}
                    >
                        + 매체, 트래커 추가
                    </Button>
                </Stack>
            }
            headerBarOnScroll={true}
            title={
                <TitleSection
                    sx={{ paddingTop: '25px' }}
                    label="매체, 트래커 정보 관리"
                    buttonLabel="+ 매체, 트래커 추가"
                    buttonClick={() => navigate('/basic/add-media')}
                    buttonDisabled={business?.my_role === BizMgrRole.Viewer}
                />
            }
        >
            {channels.length !== 0 ? (
                <Box
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    gap="20px"
                >
                    {channels.map(([channel, value]: any) => {
                        const channelInfo = go<ChannelSimple>(
                            channelList,
                            find(propEq('type', channel)),
                        )
                        return (
                            <MediaCard
                                key={channel}
                                img={channelInfo.logo_url!}
                                name={channelInfo.name}
                                type={channelCategoryLabel(
                                    channelInfo.category,
                                )}
                                count={value.length}
                                onClick={() => {
                                    setVisible(true)
                                    setChannel(channelInfo)
                                }}
                            />
                        )
                    })}
                </Box>
            ) : (
                <NoResultMessage
                    message="추가된 매체, 트래커가 없습니다. 매체, 트래커를 추가해 주세요."
                    sx={{ padding: '200px 0' }}
                />
            )}
            {channel && (
                <DialogEditAccount
                    channel={channel}
                    open={visible}
                    onClose={() => setVisible(false)}
                />
            )}
        </ScrollCard>
    )
}
