import { Box, Grid, Typography } from '@mui/material'
import { THEME } from 'constants/index'

interface IProps {
    message: string
    sx?: any
}
export default function NoResultMessage({ message, sx }: IProps) {
    return (
        <Box
            sx={{
                textAlign: 'center',
                margin: 'auto',
                bgcolor: 'grey.100',
                borderRadius: THEME.borderRadius2,
                ...sx,
            }}
        >
            <Grid item sx={{ padding: 0, mb: '25px' }}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/smile.svg`}
                    alt="no medias"
                />
            </Grid>
            <Typography variant="body1">{message}</Typography>
        </Box>
    )
}
