import { useQuery } from 'react-query'
import { BusinessApi } from 'api/index'
import { Business } from 'model'

export default function useBusinesses() {
    return useQuery<Business[]>(
        'businesses',
        () => BusinessApi.getBusinesses(),

        {
            suspense: true,
        },
    )
}
