import { toast as hotToast } from 'react-hot-toast'

const toast = {
    success(msg) {
        hotToast.success(msg, {
            position: 'top-center',
        })
    },
    error(msg) {
        hotToast.error(msg, {
            position: 'top-right',
        })
    },
}

export default toast
