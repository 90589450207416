import BusinessCard from './basic/BusinessCard'
import { Paper, Stack } from '@mui/material'
import useBusinesses from 'queries/useBusinesses'
import { prop, propEq } from 'utils/lib'
import Select from './Select'
import useBizId from 'zustands/useBizId'
import useBusiness from 'queries/useBusiness'
import { BizMgrStatus } from 'model'
import { useLocation, useNavigate } from 'react-router-dom'

export default function SideMenuLogo() {
    const navigate = useNavigate()
    const { bizId, set: setBizId } = useBizId()
    const { data: business } = useBusiness({ bizId })
    const { data: businesses } = useBusinesses()
    const location = useLocation()

    if (!business) {
        throw Error('business is undefined')
    }
    if (!businesses) {
        throw Error('businesses is undefined')
    }

    const { name, category, image_url } = business

    return (
        <Stack
            sx={{ display: 'flex', alignItems: 'center', mt: '30px' }}
            spacing="35px"
        >
            <Paper sx={{ width: 120, height: 150 }}>
                <BusinessCard
                    name={name || ''}
                    image={image_url || ''}
                    category={category}
                    onClick={() => navigate('/basic/business-setting')}
                />
            </Paper>
            <Select
                value={bizId}
                onChange={e => {
                    setBizId({ bizId: e.target.value })
                    if (location.pathname === '/basic/edit-business') {
                        navigate('/basic/business-setting')
                    }
                }}
                options={{
                    list: businesses.filter(
                        propEq('my_status', BizMgrStatus.In),
                    ),
                    valueProp: prop('id'),
                    keyProp: prop('id'),
                    labelProp: prop('name'),
                }}
            />
        </Stack>
    )
}
