import req from 'api/req'
import { X_REFERER } from 'constants/index'
import {
    AdAccountRecipe,
    AdAccountStatus,
    AdAccountStatusChange,
    BizMgrRole,
    BizMgrStatus,
    Business,
    BusinessRecipe,
    ChannelType,
} from 'model'
import { evolve, identity, replace } from 'utils/lib'

export const postUserInfo = async userInfo =>
    await req.post(process.env.REACT_APP_ARGO_API_URL + `/auth`, userInfo)

export const getUserInfo = async (accessToken: string) =>
    await req.get(
        'https://www.googleapis.com/oauth2/v1/userinfo',
        { alt: 'json' },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    )

// 로그인
export const Auth = {
    loginGoogle: data =>
        req.post(
            process.env.REACT_APP_AUTH_API_URL + '/auth/login/google',
            data,
            {
                headers: {
                    'x-referer': X_REFERER,
                    'response-uri': window.location.origin + '/login',
                },
            },
        ),
    me: () => req.get(process.env.REACT_APP_LEVER_API_URL + '/users/me'),
    login: () => req.get('/auth/login'),
    signUp: ({ code, id_token, temp_token }) =>
        req.post('/auth/signup', { code, id_token, temp_token }),
}

export const BusinessApi = {
    getBusinesses: (): Promise<Business[]> => req.get('/businesses'),
    getAllBusinesses: (): Promise<Business[]> =>
        req.get('/businesses', { only_in: false }),
    getBusiness: ({ bizId }): Promise<Business> =>
        req.get('/businesses/' + bizId),
    addBusiness: (data: BusinessRecipe) => req.post('/businesses', data),
    updateBusiness: (data: Business) => req.put('/businesses/' + data.id, data),
    uploadImage: ({ bizId, file }) => {
        const formData = new FormData()
        formData.append('image', file)
        return req.post('/businesses/' + bizId + '/image', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
    getWebInfo: (url, option = {}) =>
        req.get('/platforms/web', { url }, option),
}

export const ManagerApi = {
    getManagers: () => req.get('/managers'),
    getManager: id => req.get('/managers/' + id),
    reloadManager: id => req.put('/managers/' + id, undefined),
}

export const BusinessManagerApi = {
    getManagers: ({ bizId }) => req.get('/businesses/' + bizId + '/managers'),
    requestInvite: ({ bizId, mgrId, role }) =>
        req.post('/businesses/' + bizId + '/managers/' + mgrId + '/invite', {
            role,
        }),
    responseInvite: ({ bizId, mgrId, before, after }) =>
        req.put('/businesses/' + bizId + '/managers/' + mgrId + '/invite', {
            before,
            after,
        }),
    requestJoin: ({ bizId, mgrId }) =>
        req.post('/businesses/' + bizId + '/managers/' + mgrId + '/join', {
            biz_id: bizId,
            mgr_id: mgrId,
            status: BizMgrStatus.Requested,
            role: BizMgrRole.Viewer,
        }),
    responseJoin: ({ bizId, mgrId, before, after }) =>
        req.put('/businesses/' + bizId + '/managers/' + mgrId + '/join', {
            before,
            after,
        }),
    updateRole: (param: {
        bizId: number
        mgrId: number
        before: BizMgrRole
        after: BizMgrRole
    }) =>
        req.put(`/businesses/${param.bizId}/managers/${param.mgrId}/role`, {
            before: param.before,
            after: param.after,
        }),
    changeMaster: (params: { bizId: number; mgrId: number }) =>
        req.put(
            `/businesses/${params.bizId}/managers/${params.mgrId}/master`,
            undefined,
        ),
    updateStatus: ({ bizId, member, status }) =>
        req.put(`/businesses/${bizId}/managers/${member.id}/status`, {
            before: member.status,
            after: status,
        }),
    deleteManager: ({ bizId, mgrId }) =>
        req.delete(`/businesses/${bizId}/managers/${mgrId}`),
}

export const Channel = {
    getChannels: () => req.get('/channels'),
}

export const AdAccountApi = {
    getAdAccounts: ({ bizId, channel, withAccounts }) =>
        req.get(`/businesses/${bizId}/adaccounts`, {
            channel,
            with_accounts: withAccounts,
        }),
    getAdAccount: (bizId: number, adAccountId: number) =>
        req.get(`/businesses/${bizId}/adaccounts/${adAccountId}`),
    addAdAccount: (bizId: number, payload: Omit<AdAccountRecipe, 'status'>) =>
        req.post(`/businesses/${bizId}/adaccounts`, {
            channel: payload.channel,
            info: evolve(
                {
                    customer_id:
                        payload.channel === ChannelType.Google
                            ? replace(/-/g, '')
                            : identity,
                    login_customer_id:
                        payload.channel === ChannelType.Google
                            ? replace(/-/g, '')
                            : identity,
                },
                payload.info,
            ),
            config: payload.config,
            status: AdAccountStatus.Standby,
        }),
    updateAdAccount: (
        bizId: number,
        adAccountId: number,
        payload: AdAccountRecipe,
    ) =>
        req.put(`/businesses/${bizId}/adaccounts/${adAccountId}`, {
            info: payload.info,
            config: payload.config,
            channel: payload.channel,
            status: payload.status,
        }),
    deleteAdAccount: ({ bizId, adAccountId }) =>
        req.delete(`/businesses/${bizId}/adaccounts/${adAccountId}`),
    changeStatus: (
        bizId: number,
        adAccountId: number,
        payload: AdAccountStatusChange,
    ) =>
        req.put(`/businesses/${bizId}/adaccounts/${adAccountId}/status`, {
            before: payload.before,
            after: payload.after,
        }),
    uploadPem: ({ file }) => {
        const formData = new FormData()
        formData.append('account_file', file)
        return req.post('/upload/account_auth', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },
}
