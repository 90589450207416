import { createElement, lazy } from 'react'
import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'
import Channels from 'views/basic/channels'

const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')))
const UtilsTypography = Loadable(
    lazy(() => import('views/design-guide/Typography')),
)
const UtilsColor = Loadable(lazy(() => import('views/design-guide/Color')))
const UtilsShadow = Loadable(lazy(() => import('views/design-guide/Shadow')))
const UtilsBreakpoint = Loadable(
    lazy(() => import('views/design-guide/Breakpoint')),
)
const UtilsMaterialIcons = Loadable(
    lazy(() => import('views/design-guide/MaterialIcons')),
)
const UtilsTablerIcons = Loadable(
    lazy(() => import('views/design-guide/TablerIcons')),
)
const Report = Loadable(lazy(() => import('views/basic/report')))
const Solution = Loadable(lazy(() => import('views/basic/solution')))
const Extract = Loadable(lazy(() => import('views/basic/extract')))
const ExtractWip = Loadable(lazy(() => import('views/basic/extract-wip')))
const ExtractData = Loadable(
    lazy(() => import('views/basic/extract/ExtractData')),
)
const ExtractPeriodicity = Loadable(
    lazy(() => import('views/basic/extract/ExtractPeriodicity')),
)
const ChannelRefresh = Loadable(
    lazy(() => import('views/basic/extract/ChannelRefresh')),
)
const BusinessSetting = Loadable(
    lazy(() => import('views/basic/settings/business-setting')),
)
const EditBusiness = Loadable(
    lazy(() => import('views/basic/settings/edit-business')),
)
const ManageMember = Loadable(
    lazy(() => import('views/basic/settings/manage-member')),
)

const AddMedia = Loadable(
    lazy(() => import('views/basic/channels/add-account')),
)
const ButtonDoc = Loadable(
    lazy(() => import('views/design-guide/components/button-doc')),
)

const TableDoc = Loadable(
    lazy(() => import('views/design-guide/components/table-doc')),
)

const DateRangePickerDoc = Loadable(
    lazy(() => import('views/design-guide/components/date-range-picker-doc')),
)
const RadioDoc = Loadable(
    lazy(() => import('views/design-guide/components/radio-doc')),
)

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: lazyElement(() => import('views/basic/channels')),
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />,
        },
        {
            path: '/design-guide/util-typography',
            element: <UtilsTypography />,
        },
        {
            path: '/design-guide/util-color',
            element: <UtilsColor />,
        },
        {
            path: '/design-guide/util-shadow',
            element: <UtilsShadow />,
        },
        {
            path: '/design-guide/util-breakpoint',
            element: <UtilsBreakpoint />,
        },
        {
            path: '/icons/tabler-icons',
            element: <UtilsTablerIcons />,
        },
        {
            path: '/icons/material-icons',
            element: <UtilsMaterialIcons />,
        },
        {
            path: '/basic/channels',
            element: lazyElement(() => import('views/basic/channels')),
        },
        {
            path: '/basic/report',
            element: <Report />,
        },
        {
            path: '/basic/report/total-report',
            element: lazyElement(
                () => import('views/basic/report/total-report'),
            ),
        },
        {
            path: '/basic/report/total-report/report-data',
            element: lazyElement(
                () => import('views/basic/report/total-report/report-data'),
            ),
        },
        {
            path: '/basic/report/manage-data',
            element: lazyElement(
                () => import('views/basic/report/manage-data'),
            ),
        },
        {
            path: '/basic/report/index-rule',
            element: lazyElement(() => import('views/basic/report/index-rule')),
        },
        {
            path: '/basic/report/customizing-rule',
            element: lazyElement(
                () => import('views/basic/report/customizing-rule'),
            ),
        },

        {
            path: '/basic/solution',
            element: <Solution />,
        },
        {
            path: '/basic/channels',
            element: <Channels />,
        },
        {
            path: '/basic/extract',
            element: <Extract />,
        },
        {
            path: '/basic/extract-wip',
            element: <ExtractWip />,
        },
        {
            path: '/basic/extract-data',
            element: <ExtractData />,
        },
        { path: '/basic/extract-periodicity', element: <ExtractPeriodicity /> },
        { path: '/basic/channel-refresh', element: <ChannelRefresh /> },
        {
            path: '/basic/business-setting',
            element: <BusinessSetting />,
        },
        {
            path: '/basic/edit-business',
            element: <EditBusiness />,
        },
        {
            path: '/basic/manage-member',
            element: <ManageMember />,
        },
        {
            path: '/basic/add-media',
            element: <AddMedia />,
        },
        {
            path: '/design-guide/components/button-doc',
            element: <ButtonDoc />,
        },
        {
            path: '/design-guide/components/table-doc',
            element: <TableDoc />,
        },
        {
            path: '/design-guide/components/date-range-picker-doc',
            element: <DateRangePickerDoc />,
        },
        {
            path: '/design-guide/components/radio-doc',
            element: <RadioDoc />,
        },
        {
            path: '/design-guide/components/select-doc',
            element: lazyElement(
                () => import('views/design-guide/components/select-doc'),
            ),
        },
        {
            path: '/design-guide/components/multi-select-doc',
            element: lazyElement(
                () => import('views/design-guide/components/multi-select-doc'),
            ),
        },
        {
            path: '/design-guide/components/date-picker-doc',
            element: lazyElement(
                () => import('views/design-guide/components/date-picker-doc'),
            ),
        },
        {
            path: '/design-guide/components/svg-icons-doc',
            element: lazyElement(
                () => import('views/design-guide/components/svg-icons-doc'),
            ),
        },
        {
            path: '/design-guide/components/checkbox-group-doc',
            element: lazyElement(
                () =>
                    import('views/design-guide/components/checkbox-group-doc'),
            ),
        },
        {
            path: '/design-guide/components/collapse-doc',
            element: lazyElement(
                () => import('views/design-guide/components/collapse-doc'),
            ),
        },
        {
            path: '/design-guide/components/popover-menu-doc',
            element: lazyElement(
                () => import('views/design-guide/components/popover-menu-doc'),
            ),
        },
        {
            path: '/design-guide/components/dialog-doc',
            element: lazyElement(
                () => import('views/design-guide/components/dialog-doc'),
            ),
        },
        {
            path: '/design-guide/components/box-list-doc',
            element: lazyElement(
                () => import('views/design-guide/components/box-list-doc'),
            ),
        },
    ],
}

export default MainRoutes

function lazyElement(factory) {
    return createElement(Loadable(lazy(factory)))
}

/*
 * 자동으로 프로젝트 폴더 구조에 따라 라우팅 처리를 하고 싶었지만,
 * import 인자로 변수가 전달할 경우 기대했던 대로 동작하지 않음. 2022.5.31 Keating
 * */
const routesByPathList = process.env.PATH_LIST!.split(',').map(path => ({
    path,
    element: lazyElement(() => import('views' + path)),
}))
