import React from 'react'
import { IError } from '../ErrorBoundary'
import { apiErrorReport } from 'biz/index'
import CopiableText from './CopiableText'
import { Stack, Typography } from '@mui/material'
import Details from './Details'
import { SCREEN_MESSAGES } from 'constants/messages'

export default function ErrorScreen({ error, errorInfo }: IError) {
    const isApiError = Boolean(error.response?.config || error.config)
    const errorLog = isApiError
        ? apiErrorReport(error)
        : error.message + '\n' + errorInfo.componentStack

    const message = SCREEN_MESSAGES.find(item => item.pred(errorLog!))
    if (message) {
        return (
            <Stack sx={{ p: '20px' }}>
                <h2>{message.title}</h2>
                <Typography sx={{ color: 'grey.700', fontStyle: 'italic' }}>
                    {typeof message.desc === 'function'
                        ? message.desc(error)
                        : message.desc}
                </Typography>
                <p style={{ marginTop: '30px' }}>{message.button}</p>
            </Stack>
        )
    }

    return (
        <Stack style={{ padding: 20 }} spacing="20px">
            <CopiableText target={errorLog}>
                <h2 style={{ margin: '0px' }}>{error.message}</h2>
            </CopiableText>
            {isApiError && (
                <Details summary="error-details" open>
                    {errorLog}
                </Details>
            )}
            <Details summary="error-component-stack" open>
                {errorInfo.componentStack}
            </Details>
        </Stack>
    )
}
