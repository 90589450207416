import { Box, Popover, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { THEME } from 'constants/index'

interface Menu {
    label: React.ReactNode
    sx?: any
    onClick?: any
}

interface IProps {
    open: boolean
    anchorEl: any
    onClose: any
    list: Menu[]
}

export default function PopoverMenu({ open, anchorEl, onClose, list }: IProps) {
    const [selected, setSelected] = useState<number>(-1)

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    transform: 'translateX(-10px) !important',
                    boxShadow: THEME.boxShadow,
                    border: '1px solid',
                    borderColor: 'grey.300',
                },
            }}
        >
            <Stack
                spacing="10px"
                sx={{
                    minWidth: 128,
                    p: '10px',
                    '& > div.MuiBox-root': {
                        '& > p': {
                            cursor: 'pointer',
                            p: '10px',
                            borderRadius: THEME.borderRadius,
                        },
                        borderRadius: THEME.borderRadius,
                    },
                    '& > div.MuiBox-root:hover': {
                        bgcolor: 'grey.100',
                    },
                }}
            >
                {list.map((item, idx) => (
                    <Box
                        key={idx}
                        sx={{
                            bgcolor: idx === selected ? 'grey.100' : undefined,
                        }}
                    >
                        <Typography
                            sx={item.sx}
                            onClick={(e: any) => {
                                setSelected(idx)
                                item.onClick(e)
                            }}
                        >
                            {item.label}
                        </Typography>
                    </Box>
                ))}
            </Stack>
        </Popover>
    )
}
