import {
    Box,
    FormControl,
    FormControlLabel,
    FormLabel,
    Typography,
} from '@mui/material'
import AD_ACCOUNTS_FORM from 'constants/accountForm'
import { Fragment } from 'react'
import { Radio, RadioGroup } from 'ui-component/RadioGroup'
import {
    always,
    assoc,
    find,
    go,
    ifElse,
    isEmpty,
    isNil,
    last,
    oneOf,
    propEq,
    split,
} from 'utils/lib'
import Input from 'ui-component/Input'
import Button from 'ui-component/Button'
import { ChannelType } from 'model/channel-type'
import { AdAccountConfig, AdAccountInfo } from '../../../types'
import useBusiness from 'queries/useBusiness'
import useBizId from 'zustands/useBizId'
import { BizMgrRole } from 'model/biz-mgr-role'
import FileUpload from '../../../ui-component/FileUpload'
import { AdAccountApi } from 'api/index'
import { asyncLoading, getDynamicInputList } from 'biz/index'
import toast from 'biz/toast'
import CheckboxGroup from '../../../ui-component/CheckboxGroup'

interface IProps {
    type: ChannelType
    info: AdAccountInfo
    setInfo: any
    config: AdAccountConfig
    setConfig: any
}

export default function AddAccountsForm({
    type,
    info,
    setInfo,
    config,
    setConfig,
}: IProps) {
    const { data: business } = useBusiness(useBizId())

    const buildForm = (form, idx = 0) => {
        if (form.type === 'radio') {
            return renderRadio({
                form,
                config,
                setInfo,
                setConfig,
                buildForm,
                business,
                idx,
            })
        } else if (form.type === 'checkbox') {
            return renderCheckbox({
                form,
                config,
                setInfo,
                setConfig,
                buildForm,
                business,
                idx,
            })
        }
        return (
            <>
                {form.flatMap(item =>
                    renderResult({ item, info, business, setInfo, config }),
                )}
            </>
        )
    }

    return (
        <FormControl
            fullWidth
            sx={{
                '>.MuiFormLabel-root': {
                    display: 'inline-block',
                    margin: '50px 0 16px',
                    fontSize: '16px',
                    color: 'text.dark',
                    fontWeight: '700',
                    '&:first-of-type': {
                        mt: '20px',
                    },
                },
                '>.MuiFormLabel-root.Mui-focused': {
                    color: 'text.dark',
                },
            }}
        >
            {buildForm(AD_ACCOUNTS_FORM[type])}
        </FormControl>
    )
}

export const renderRadio = ({
    form,
    config,
    setInfo,
    setConfig,
    buildForm,
    business,
    idx,
}) => (
    <>
        <FormLabel>{form.label}</FormLabel>
        <RadioGroup
            row
            value={toYesOrNo(config[form.key])}
            onChange={radioOnChange({ setInfo, setConfig, form })}
        >
            {form.options.map(item => (
                <FormControlLabel
                    disabled={business?.my_role === BizMgrRole.Viewer}
                    key={item.label}
                    control={<Radio />}
                    label={item.label}
                    value={item.value}
                    onClick={() => {
                        const asis = config[form.key]
                        item.onClick?.(close => {
                            setConfig(assoc(form.key, asis))
                            close()
                        }, config)
                    }}
                />
            ))}
        </RadioGroup>
        {form.notice && <Notice>{form.notice}</Notice>}
        {go(
            form.options,
            find(propEq('value', toYesOrNo(config[form.key]))),
            ifElse(isNil, always(null), option =>
                buildForm(form[option.value], idx + 1),
            ),
        )}
        {form.footer && <Footer>{form.footer}</Footer>}
    </>
)

export const radioOnChange =
    ({ setInfo, setConfig, form }) =>
    e => {
        setInfo({})
        setConfig(config => ({
            ...config,
            [form.key]: e.target.value === 'yes',
            ...getDependentKeyMap(form),
        }))
        // if (Array.isArray(form[e.target.value])) {
        //     /*
        //      * 구글애즈 yes-no 선택시에,
        //      * Login Customer ID 에 '580-156-4206' 를 기본값으로 세팅하기 위해
        //      * accountForm.ts 데이터구조에서 input 항목에 value 옵션을 추가하였고,
        //      * 해당 기본값 value 를 처리하기 위한 코드가 추가됨 22.4.4 Keating
        //      * */
        //     form[e.target.value].forEach(item => {
        //         if (item.value) {
        //             setInfo(assoc(item.key, item.value))
        //         }
        //     })
        // }
    }

export const renderCheckbox = ({
    form,
    config,
    setInfo,
    setConfig,
    buildForm,
    business,
    idx,
}) => {
    const value = config[form.key] ?? []
    return (
        <>
            <FormLabel>{form.label}</FormLabel>
            <CheckboxGroup
                disabled={business?.my_role === BizMgrRole.Viewer}
                options={form.options}
                value={value}
                setValue={value => setConfig(assoc(form.key, value))}
            />
            {form.notice && <Notice>{form.notice}</Notice>}
            {buildForm(
                isEmpty(config[form.key] ?? []) ? [] : form.always,
                idx + 1,
            )}
        </>
    )
}

export const renderInput = ({ item, info, business, setInfo }) => (
    <Fragment key={item.label}>
        <FormLabel>{item.label}</FormLabel>
        <Input
            placeholder={item.placeholder}
            value={item.value || info[item.key] || ''}
            disabled={item.disabled || business?.my_role === BizMgrRole.Viewer}
            onChange={e =>
                setInfo(assoc(item.key, e.target.value.replaceAll(' ', '')))
            }
        />
        {item.notice && <Notice>{item.notice}</Notice>}
    </Fragment>
)

export const renderFile = ({ item, info, setInfo }) => (
    <Fragment key={item.label}>
        <FormLabel>{item.label}</FormLabel>
        <FileUpload
            key={item.label}
            s3Url={
                item.value ||
                (info[item.key] ? go(info[item.key], split('/'), last) : '')
            }
            setFile={asyncLoading(async file => {
                if (!file) {
                    return
                }
                const tempPath = await AdAccountApi.uploadPem({ file })
                setInfo(assoc(item.key, tempPath))
                toast.success('.pem 파일 임시저장 완료')
            })}
            removeClick={() => setInfo(assoc(item.key, undefined))}
        />
        {item.notice && <Notice>{item.notice}</Notice>}
    </Fragment>
)

const toYesOrNo = value =>
    oneOf(
        [
            [value === true, 'yes'],
            [value === false, 'no'],
        ],
        null,
    )

export const renderResult = ({ item, info, business, setInfo, config }) => {
    if (item.type === 'input') {
        return renderInput({
            item,
            info,
            business,
            setInfo,
        })
    }

    if (item.type === 'file') {
        return renderFile({ item, info, setInfo })
    }

    if (item.type === 'button') {
        return (
            <Box key={item.label} sx={{ mt: '30px' }}>
                <Button onClick={() => window.open(item.url, '_blank')}>
                    {item.label}
                </Button>
            </Box>
        )
    }

    if (item.type === 'dynamic') {
        return getDynamicInputList(item, config).map(item =>
            renderResult({
                item,
                info,
                business,
                setInfo,
                config,
            }),
        )
    }

    throw Error('Invalid item type: ' + item.type)
}

export const getDependentKeyMap = (form, acc: any = {}) => {
    // 해당 form 하위 질문들의 키값들만 수집
    return Object.values(form).reduce((acc: any, value: any) => {
        if (Array.isArray(value) || typeof value !== 'object') {
            return acc
        }
        return getDependentKeyMap(value, assoc(value.key, undefined, acc))
    }, acc)
}

function Notice({ children }) {
    return (
        <div style={{ padding: '20px 0 0 10px' }}>
            {children.map(value => (
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                    }}
                    key={value}
                >
                    • {value}
                </Typography>
            ))}
        </div>
    )
}

function Footer({ children }) {
    return (
        <div style={{ paddingTop: '40px' }}>
            {children.map(value => (
                <Typography
                    sx={{
                        fontSize: '12px',
                        fontWeight: '700',
                    }}
                    key={value}
                >
                    * {value}
                </Typography>
            ))}
        </div>
    )
}
