import { Grid, Typography } from '@mui/material'
import Button from 'ui-component/Button'

interface IProps {
    label: string
    buttonLabel?: string | boolean
    buttonClick: any
    buttonDisabled?: boolean
    sx?: any
}

export default function TitleSection({
    label,
    buttonLabel,
    buttonClick,
    buttonDisabled,
    sx = {},
}: IProps) {
    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={sx}
            >
                <Grid item>
                    <Typography variant="h1">{label}</Typography>
                </Grid>
                <Grid item>
                    {buttonLabel && (
                        <Button
                            variant="contained"
                            onClick={buttonClick}
                            disabled={buttonDisabled}
                        >
                            {buttonLabel}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </>
    )
}
