import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'ui-component/Dialog'
import Button from 'ui-component/Button'
import MediaTitleSection from './MediaTitleSection'
import useBizId from 'zustands/useBizId'
import useAdAccounts from 'queries/useAdAccounts'
import { isEmpty } from 'utils/lib'
import CardAdAccount from 'views/basic/channels/CardAdAccount'
import { Stack, Typography } from '@mui/material'
import { AdAccountSimple } from 'model/ad-account-simple'
import DialogAdAccountForm from 'views/basic/channels/DialogAdAccountForm'
import { useState } from 'react'
import NoResultMessage from '../../../ui-component/NoResultMessage'
import { AdAccount } from 'model/ad-account'
import { DumbFunction } from '../../../types'
import { ChannelSimple } from 'model/channel-simple'
import useBusiness from 'queries/useBusiness'
import { BizMgrRole } from 'model/biz-mgr-role'

interface IProps {
    open: boolean
    onClose: DumbFunction
    channel: ChannelSimple
}

export default function DialogEditAccount({ open, onClose, channel }: IProps) {
    const { bizId } = useBizId()
    const { refetch: refetchAdAccounts1 } = useAdAccounts<AdAccountSimple>({
        bizId,
    })
    const { data: adAccounts, refetch: refetchAdAccounts2 } =
        useAdAccounts<AdAccount>({
            bizId,
            channel: channel.type,
            withAccounts: true,
        })
    const [visible, setVisible] = useState(false)
    const { data: business } = useBusiness({ bizId })
    const refetch = () =>
        Promise.all([refetchAdAccounts1(), refetchAdAccounts2()])
    return (
        <Dialog open={open} onClose={onClose} fullWidth={true}>
            <DialogTitle sx={{ backgroundColor: 'grey.50' }}>
                <MediaTitleSection
                    name={channel.name || ''}
                    img={channel.logo_url || ''}
                    type={channel.category || ''}
                />
            </DialogTitle>
            <DialogContent
                sx={{ '&.MuiDialogContent-root ': { padding: '40px' } }}
            >
                <Typography variant="h4" color="grey.900">
                    연동된 광고 계정
                </Typography>
                <Stack spacing="21px" mt="16px" mb="21px">
                    {isEmpty(adAccounts) ? (
                        <NoResultMessage
                            message="등록된 계정이 없습니다."
                            sx={{ width: '100%', padding: '20px' }}
                        />
                    ) : (
                        adAccounts!.map((item: AdAccount) => (
                            <CardAdAccount
                                key={item.id}
                                adAccount={item}
                                refetch={refetch}
                            />
                        ))
                    )}
                </Stack>
                {business?.my_role !== BizMgrRole.Viewer && (
                    <Button onClick={() => setVisible(true)}>
                        + 계정 추가
                    </Button>
                )}
                <DialogAdAccountForm
                    open={visible}
                    onClose={() => setVisible(false)}
                    type={channel.type}
                    refetch={refetch}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" size="large">
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    )
}
