/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ChannelType {
    Facebook = 'facebook',
    Google = 'google',
    NaverKeyword = 'naver_keyword',
    Nosp = 'nosp',
    KakaoMoment = 'kakao_moment',
    KakaoKeyword = 'kakao_keyword',
    KakaoBrand = 'kakao_brand',
    AppleSearch = 'apple_search',
    Twitter = 'twitter',
    Tiktok = 'tiktok',
    Criteo = 'criteo',
    Rtbhouse = 'rtbhouse',
    Remerge = 'remerge',
    Moloco = 'moloco',
    Mobon = 'mobon',
    Appsflyer = 'appsflyer',
    GoogleAnalytics = 'google_analytics',
    NaverGfa = 'naver_gfa',
    Airbridge = 'airbridge',
    Dfinery = 'dfinery',
}
