import {
    AdAccountAppsflyerConfig,
    AppsflyerV1DataType,
    AppsflyerV2DataType,
    ChannelType,
} from 'model'
import { USE_MADUP_ACCOUNT } from 'constants/index'
import { confirm } from '../Confirmer'

const yesNoOptions = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
]

const guideButton = url => ({
    label: '정보 입력 가이드 확인하기 >',
    type: 'button',
    url,
})

const googleAds = {
    label: '연동할 계정이 ponplead01@gmail.com에 연결이 되어 있나요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: {
        label: '광고 계정에 대해 사용자 권한만 받아놓은 상태이신가요?',
        type: 'radio',
        key: 'use_madup_mcc',
        tooltip: 'MCC 연결이 되어 있지 않은 상태',
        options: yesNoOptions,
        yes: {
            label: '사용자 권한을 받은 계정이 관리자 계정인가요?',
            type: 'radio',
            key: 'is_admin_account',
            tooltip:
                '관리자 계정인 경우 관리자 계정 하위에 광고 계정이 존재하게 되며, 관리자 계정이 아닌 경우에는 사용자 권한을 받은 계정 자체가 광고 계정입니다.',
            options: yesNoOptions,
            yes: [
                {
                    label: 'Login Customer ID',
                    type: 'input',
                    key: 'login_customer_id',
                    placeholder: 'Login Customer ID를 입력해 주세요.',
                },
                {
                    label: 'Customer ID',
                    key: 'customer_id',
                    type: 'input',
                    placeholder: 'Customer ID를 입력해 주세요.',
                },
                guideButton(
                    'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Google-Ads',
                ),
            ],
            no: [
                {
                    label: 'Login Customer ID',
                    key: 'login_customer_id',
                    type: 'input',
                    placeholder: 'Login Customer ID를 입력해 주세요.',
                },
                {
                    label: 'Customer ID',
                    key: 'customer_id',
                    type: 'input',
                    placeholder:
                        'Login Customer ID와 동일한 값으로 노출됩니다.',
                    disabled: true,
                },
                guideButton(
                    'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Google-Ads',
                ),
            ],
        },
        no: [
            {
                label: 'Login Customer ID',
                key: 'login_customer_id',
                type: 'input',
                placeholder: 'Login Customer ID를 입력해 주세요.',
                disabled: true,
                value: '5801564206',
            },
            {
                label: 'Customer ID',
                key: 'customer_id',
                type: 'input',
                placeholder: 'Customer ID를 입력해 주세요.',
            },
            guideButton(
                'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Google-Ads',
            ),
        ],
    },
    no: [
        {
            label: 'Login Customer ID',
            type: 'input',
            key: 'login_customer_id',
            placeholder: 'Login Customer ID를 입력해 주세요.',
        },
        {
            label: 'Customer ID',
            key: 'customer_id',
            type: 'input',
            placeholder: 'Customer ID를 입력해 주세요.',
        },
        {
            label: 'Client ID',
            key: 'client_id',
            type: 'input',
            placeholder: 'Client ID를 입력해 주세요.',
        },
        {
            label: 'Client Secret',
            key: 'client_secret',
            type: 'input',
            placeholder: 'Client Secret을 입력해 주세요.',
        },
        {
            label: 'Developer Token',
            key: 'developer_token',
            type: 'input',
            placeholder: 'Developer Token을 입력해 주세요.',
        },
        {
            label: 'Refresh Token',
            key: 'refresh_token',
            type: 'input',
            placeholder: 'Refresh Token을 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Google-Ads',
        ),
    ],
}

const moloco = {
    type: 'radio',
    label: '연동할 계정이 매드업 공용 계정(duwldns@madit.kr)에 연동되어있나요?',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'AD Account ID',
            key: 'ad_account_id',
            type: 'input',
            placeholder: 'AD Account ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#moloco',
        ),
    ],
    no: [
        {
            label: 'AD Account ID',
            key: 'ad_account_id',
            type: 'input',
            placeholder: 'AD Account ID를 입력해 주세요.',
        },
        {
            label: 'ID',
            key: 'email',
            type: 'input',
            placeholder: 'ID로 사용 중인 이메일을 입력해 주세요.',
        },
        {
            label: 'PW',
            key: 'pwd',
            type: 'input',
            placeholder: 'PW를 입력해 주세요.',
        },
        {
            label: 'Workplace ID',
            key: 'workplace_id',
            type: 'input',
            placeholder: 'Workplace ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#moloco',
        ),
    ],
}

const remerge = [
    {
        label: 'ID',
        key: 'email',
        type: 'input',
        placeholder: 'ID로 사용 중인 이메일을 입력해 주세요.',
    },
    {
        label: 'PW',
        key: 'pwd',
        type: 'input',
        placeholder: 'PW를 입력해 주세요.',
    },
]

const rtbhouse = [
    {
        label: 'ID',
        key: 'email',
        type: 'input',
        placeholder: 'ID로 사용 중인 이메일을 입력해 주세요.',
    },
    {
        label: 'PW',
        key: 'pwd',
        type: 'input',
        placeholder: 'PW를 입력해 주세요.',
    },
    {
        label: 'Advertiser Hash',
        key: 'advertiser_hash',
        type: 'input',
        placeholder: 'Hash값을 입력해 주세요.',
    },
]

const naverKeyword = {
    label: '연동할 계정이 매드잇 네이버 공식 계정(wooddd12)과 연결이 되어 있나요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'Customer ID',
            key: 'customer_id',
            type: 'input',
            placeholder: 'Customer ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Naver-Search-Ads',
        ),
    ],
    no: [
        {
            label: 'Customer ID',
            key: 'customer_id',
            type: 'input',
            placeholder: 'Customer ID를 입력해 주세요.',
        },
        {
            label: '액세스라이센스',
            key: 'api_key',
            type: 'input',
            placeholder: '액세스라이센스를 입력해 주세요.',
        },
        {
            label: '비밀키',
            key: 'secret_key',
            type: 'input',
            placeholder: '비밀키를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Naver-Search-Ads',
        ),
    ],
}

const appleSearch = {
    label: '연동할 계정이 매드잇 공식 계정(ad@madit.kr)에서 생성된 계정인가요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'Org ID',
            key: 'org_id',
            type: 'input',
            placeholder: 'Org ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Apple-Search-Ads',
        ),
    ],
    no: [
        {
            label: 'Org ID',
            key: 'org_id',
            type: 'input',
            placeholder: 'Org ID를 입력해 주세요.',
        },
        {
            label: 'Client ID',
            key: 'client_id',
            type: 'input',
            placeholder: 'Client ID를 입력해 주세요.',
        },
        {
            label: 'Team ID',
            key: 'team_id',
            type: 'input',
            placeholder: 'Team ID를 입력해 주세요.',
        },
        {
            label: 'Key ID',
            key: 'key_id',
            type: 'input',
            placeholder: 'Key ID를 입력해 주세요.',
        },
        {
            label: '.pem 파일 업로드',
            key: 'cert_pem_path',
            type: 'file',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Apple-Search-Ads',
        ),
    ],
}

const twitter = {
    label: '연동할 계정이 매드잇 공식 계정(ad@madit.kr)과 연결이 되어 있나요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'Account ID',
            key: 'account_id',
            type: 'input',
            placeholder: 'Account ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Twitter-Ads',
        ),
    ],
    no: [
        {
            label: 'Account ID',
            key: 'account_id',
            type: 'input',
            placeholder: 'Account ID를 입력해 주세요.',
        },
        {
            label: 'Access Token',
            key: 'access_token',
            type: 'input',
            placeholder: 'Access Token을 입력해 주세요.',
        },
        {
            label: 'Access Token Secret',
            key: 'access_token_secret',
            type: 'input',
            placeholder: 'Access Token Secret을 입력해 주세요.',
        },
        {
            label: 'API Key',
            key: 'api_key',
            type: 'input',
            placeholder: 'API Key를 입력해 주세요.',
        },
        {
            label: 'API Key Secret',
            key: 'api_key_secret',
            type: 'input',
            placeholder: 'API Key Secret을 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Twitter-Ads',
        ),
    ],
}

const tiktok = {
    label: '연동할 계정이 매드잇 공식 계정(ad@madit.kr)과 연결이 되어 있나요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'Advertiser ID',
            key: 'advertiser_id',
            type: 'input',
            placeholder: 'Advertiser ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#TikTok-Ads',
        ),
    ],
    no: [
        {
            label: 'Advertiser ID',
            key: 'advertiser_id',
            type: 'input',
            placeholder: 'Advertiser ID를 입력해 주세요.',
        },
        {
            label: 'Access Token',
            key: 'access_token',
            type: 'input',
            placeholder: 'Access Token을 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#TikTok-Ads',
        ),
    ],
}
const reportPlaceholder =
    '예시) af_content_view, af_purchase, af_initiate_checkout, af_complete_registration'
const rawReportInputs = [
    {
        label: 'Raw 리포트 : Media Source',
        key: 'media_source',
        type: 'input',
        placeholder:
            '예시) facebook_int, google_adwords_int, kakao_int, criteo_int',
    },
    {
        label: 'Raw 리포트 : Event',
        key: 'raw_event',
        type: 'input',
        placeholder: reportPlaceholder,
    },
]
const appsInputList = [
    {
        type: 'dynamic',
        options: [
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.use_api_v1 ||
                    config.use_api_v2 ||
                    config[USE_MADUP_ACCOUNT],
                list: [
                    {
                        label: 'App ID',
                        key: 'app_id',
                        type: 'input',
                        placeholder: 'App ID를 입력해 주세요.',
                    },
                    {
                        label: 'ID',
                        key: 'id',
                        type: 'input',
                        placeholder: 'ID로 사용 중인 이메일을 입력해 주세요.',
                    },
                    {
                        label: 'PW',
                        key: 'pwd',
                        type: 'input',
                        placeholder: 'PW를 입력해 주세요',
                    },
                ],
            },
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.use_api_v1 === true,
                list: [
                    {
                        label: 'API v1',
                        key: 'api_token_v1',
                        type: 'input',
                        placeholder: 'API v1값을 입력해 주세요.',
                    },
                ],
            },
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.use_api_v2 === true,
                list: [
                    {
                        label: 'API v2',
                        key: 'api_token_v2',
                        type: 'input',
                        placeholder: 'API v2값을 입력해 주세요.',
                    },
                ],
            },
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.v1_report_type?.includes(AppsflyerV1DataType.Raw),
                list: rawReportInputs,
            },
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.v1_report_type?.includes(
                        AppsflyerV1DataType.Retention,
                    ),
                list: [
                    {
                        label: 'Retention 리포트 : Event',
                        key: 'retention_event',
                        type: 'input',
                        placeholder: reportPlaceholder,
                    },
                ],
            },
            {
                pred: (config: AdAccountAppsflyerConfig) =>
                    config.v2_report_type?.includes(AppsflyerV2DataType.Cohort),
                list: [
                    {
                        label: 'Cohort 리포트: Event',
                        key: 'cohort_event',
                        type: 'input',
                        placeholder: reportPlaceholder,
                    },
                ],
            },
        ],
    },
]

const useApiV2 = {
    label: 'API v2를 사용하시나요?',
    type: 'radio',
    key: 'use_api_v2',
    notice: [
        'Skan, Cohort 데이터를 활용하시려면 API v2를 필수로 사용해 주세요. 에이전시 계정이 아닌 팀 멤버 계정에서만 확인 가능합니다.',
    ],
    options: [
        { label: 'Yes', value: 'yes' },
        {
            label: 'No',
            value: 'no',
            onClick: (onCancel, config: AdAccountAppsflyerConfig) => {
                if (!config.use_api_v1) {
                    confirm({
                        title: '최소 하나의 API 사용이 필요합니다.',
                        desc: 'API 사용허가를 받지 못하신 경우 APT에 별도 문의 부탁드립니다.',
                        cancelButtonHidden: true,
                    })
                }
            },
        },
    ],
    yes: {
        label: 'API v2와 연동할 리포트 타입을 선택해 주세요. (중복 선택 가능)',
        type: 'checkbox',
        key: 'v2_report_type',
        options: [
            {
                label: 'Skan',
                value: AppsflyerV2DataType.Skan,
            },
            {
                label: 'Cohort',
                value: AppsflyerV2DataType.Cohort,
            },
        ],
        always: appsInputList,
    },
    no: appsInputList,
}

const appsflyer = {
    label: '연동할 계정이 매드잇 공식 계정(ad@madit.kr)과 연결이 되어 있나요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: {
        label: '연동할 리포트 타입을 선택해 주세요. (중복 선택 가능)',
        type: 'checkbox',
        key: 'v1_report_type',
        options: [
            { label: 'Raw', value: AppsflyerV1DataType.Raw },
            { label: 'Retention', value: AppsflyerV1DataType.Retention },
            { label: 'Aggregate', value: AppsflyerV1DataType.Aggregate },
        ],
        always: [
            {
                label: 'App ID',
                key: 'app_id',
                type: 'input',
                placeholder: 'App ID를 입력해 주세요.',
            },
            {
                type: 'dynamic',
                options: [
                    {
                        pred: (config: AdAccountAppsflyerConfig) =>
                            config.v1_report_type?.includes(
                                AppsflyerV1DataType.Raw,
                            ),
                        list: rawReportInputs,
                    },
                    {
                        pred: (config: AdAccountAppsflyerConfig) =>
                            config.v1_report_type?.includes(
                                AppsflyerV1DataType.Retention,
                            ),
                        list: [
                            {
                                label: 'Retention 리포트 : Event',
                                key: 'retention_event',
                                type: 'input',
                                placeholder: reportPlaceholder,
                            },
                        ],
                    },
                ],
            },
        ],
    },
    no: {
        label: 'API v1을 사용하시나요?',
        type: 'radio',
        key: 'use_api_v1',
        options: yesNoOptions,
        notice: [
            '일반적인 데이터(로우데이터, 집약형 데이터 등)가 필요하시다면 API v1을 사용해 주세요.',
        ],
        yes: {
            label: 'API v1과 연동할 리포트 타입을 선택해 주세요. (중복 선택 가능)',
            type: 'checkbox',
            key: 'v1_report_type',
            options: [
                { label: 'Raw', value: AppsflyerV1DataType.Raw },
                { label: 'Retention', value: AppsflyerV1DataType.Retention },
                { label: 'Aggregate', value: AppsflyerV1DataType.Aggregate },
            ],
            always: useApiV2,
        },
        no: useApiV2,
        footer: [
            'API 사용허가를 받지 못하신 경우 APT에 별도 문의 부탁드립니다.',
        ],
    },
}

const criteo = {
    label: '매드업 공식 계정에 연동되어 있으신가요?',
    type: 'radio',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: {
        label: 'Madup Inc 앱을 사용해 API를 사용하시나요?',
        key: 'use_madup_app',
        type: 'radio',
        options: yesNoOptions,
        yes: [
            {
                label: 'Advertiser ids',
                key: 'advertiser_ids',
                type: 'input',
                placeholder: 'Advertiser ids를 입력해 주세요.',
            },
            guideButton(
                'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#criteo',
            ),
        ],
        no: [
            {
                label: 'Advertiser ids',
                key: 'advertiser_ids',
                type: 'input',
                placeholder: 'Advertiser ids를 입력해 주세요.',
            },
            {
                label: 'Client ID',
                key: 'client_id',
                type: 'input',
                placeholder: 'Client ID를 입력해 주세요.',
            },
            {
                label: 'Client Secret',
                key: 'client_secret',
                type: 'input',
                placeholder: 'Client Secret을 입력해 주세요.',
            },
            guideButton(
                'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#criteo',
            ),
        ],
    },
    no: [
        {
            label: 'Advertiser ids',
            key: 'advertiser_ids',
            type: 'input',
            placeholder: 'Advertiser ids를 입력해 주세요.',
        },
        {
            label: 'Client ID',
            key: 'client_id',
            type: 'input',
            placeholder: 'Client ID를 입력해 주세요.',
        },
        {
            label: 'Client Secret',
            key: 'client_secret',
            type: 'input',
            placeholder: 'Client Secret을 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#criteo',
        ),
    ],
}

const accountIdInput = {
    label: 'Account ID',
    key: 'account_id',
    type: 'input',
    placeholder: 'Account ID를 입력해 주세요.',
}

const kakaoInputs = [
    accountIdInput,
    {
        label: 'Client ID',
        key: 'client_id',
        type: 'input',
        placeholder: 'Client ID을 입력해 주세요.',
    },
    {
        label: 'Access Token',
        key: 'access_token',
        type: 'input',
        placeholder: 'Access Token을 입력해 주세요.',
    },
    {
        label: 'Refresh Token',
        key: 'refresh_token',
        type: 'input',
        placeholder: 'Refresh Token을 입력해 주세요.',
    },
]

const kakao = url => ({
    type: 'radio',
    label: '연동할 계정이 ad@madit.kr에 연결이 되어 있나요?',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: {
        type: 'radio',
        label: 'API 권한 부여를 하시겠습니까?',
        key: 'grant_api_permission',
        options: yesNoOptions,
        notice: [
            '매드잇에서 사용하는 마스터 계정에 묶인 경우 Account ID만 입력해도 자동화 활용이 가능하지만 더 원활한 자동화제공이 가능합니다.',
        ],
        yes: [...kakaoInputs, guideButton(url)],
        no: [accountIdInput],
    },
    no: [...kakaoInputs, guideButton(url)],
})

const facebook = {
    type: 'radio',
    label: '매드업 공식 개발자 계정에 연동하시겠습니까?',
    key: USE_MADUP_ACCOUNT,
    options: yesNoOptions,
    yes: [
        {
            label: 'Account ID',
            key: 'account_id',
            type: 'input',
            placeholder: 'Account ID를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Facebook',
        ),
    ],
    no: [
        {
            label: 'App ID',
            key: 'app_id',
            type: 'input',
            placeholder: 'App ID를 입력해 주세요.',
        },
        {
            label: 'Business ID',
            key: 'business_id',
            type: 'input',
            placeholder: 'Business ID를 입력해 주세요.',
        },
        {
            label: 'Access Token',
            key: 'access_token',
            type: 'input',
            placeholder: 'Access token 를 입력해 주세요.',
        },
        {
            label: 'Account ID',
            key: 'account_id',
            type: 'input',
            placeholder: 'Account ID를 입력해 주세요.',
        },
        {
            label: 'App Secret',
            key: 'app_secret',
            type: 'input',
            placeholder: 'App Secret 를 입력해 주세요.',
        },
        guideButton(
            'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Facebook',
        ),
    ],
}

const mobon = [
    {
        label: 'Mobon ID',
        key: 'id',
        type: 'input',
        placeholder: 'Mobon ID를 입력해 주세요.',
    },
    {
        label: 'Mobon PW',
        key: 'pwd',
        type: 'input',
        placeholder: 'Mobon PW를 입력해 주세요.',
    },
    {
        label: 'Ad Account ID',
        key: 'ad_account_id',
        type: 'input',
        placeholder: 'Ad Account ID 를 입력해 주세요.',
    },
    guideButton(
        'https://madup.atlassian.net/wiki/spaces/AD/pages/2202337424#%EB%AA%A8%EB%B9%84%EC%98%A8',
    ),
]

const nosp = [
    {
        label: 'Login Key',
        key: 'account_key',
        type: 'input',
        placeholder: 'Login Key 를 입력해 주세요.',
    },
    guideButton(
        'https://madup.atlassian.net/wiki/spaces/AD/pages/2202337424#%EB%84%A4%EC%9D%B4%EB%B2%84-NOSP',
    ),
]

const AD_ACCOUNTS_FORM = {
    [ChannelType.Google]: googleAds,
    [ChannelType.Rtbhouse]: rtbhouse,
    [ChannelType.Remerge]: remerge,
    [ChannelType.Moloco]: moloco,
    [ChannelType.NaverKeyword]: naverKeyword,
    [ChannelType.AppleSearch]: appleSearch,
    [ChannelType.Twitter]: twitter,
    [ChannelType.Tiktok]: tiktok,
    [ChannelType.Appsflyer]: appsflyer,
    [ChannelType.Criteo]: criteo,
    [ChannelType.KakaoBrand]: kakao(
        'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Kakao-keyword-ads',
    ),
    [ChannelType.KakaoKeyword]: kakao(
        'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Kakao-keyword-ads',
    ),
    [ChannelType.KakaoMoment]: kakao(
        'https://madup.atlassian.net/wiki/spaces/DP/pages/2202337424#Kakao-moment',
    ),
    [ChannelType.Facebook]: facebook,
    [ChannelType.Mobon]: mobon,
    [ChannelType.Nosp]: nosp,
}

export default AD_ACCOUNTS_FORM
