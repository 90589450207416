import { useQuery } from 'react-query'
import { Channel } from 'api/index'
import { ChannelSimple, ChannelType } from 'model'
import { propEq, reject } from 'utils/lib'

export default function useChannels() {
    return useQuery<ChannelSimple[]>(
        'channels',
        () =>
            Channel.getChannels().then(
                reject(propEq('type', ChannelType.KakaoBrand)),
            ),
        {
            suspense: true,
        },
    )
}
