import * as Sentry from '@sentry/browser'
import toast from 'biz/toast'
import ErrorMessage from 'ui-component/ErrorMessage'

export default function handleError(err) {
    if (process.env.REACT_APP_SENTRY_DISABLED !== 'true') {
        Sentry.captureException(err)
    }
    window.$logger.error(err)
    window.$logger
        .if(err.response)
        .verbose('======== err.response is below ==========')
    window.$logger.if(err.response).verbose(err.response)
    window.$logger
        .if(err.response)
        .verbose('=========================================')

    // FIXME 아래 내용 추가로 처리될 수 있으면 좋겠음 2022.03.15 Keating
    //   - 500 Network error 일 때 상태코드 500 표시
    toast.error(<ErrorMessage err={err} wrapperStyle={{ width: 300 }} />)
}
