interface IProps {
    style?: any
    onClick?: any
    circleStyle?: any
}

export default function ClearIcon({
    style,
    onClick,
    circleStyle = { fill: 'black', fillOpacity: '0.6' },
}: IProps) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            onClick={onClick}
        >
            <circle
                cx="10"
                cy="10"
                r="10"
                fill={circleStyle.fill}
                fillOpacity={circleStyle.fillOpacity}
            />
            <path d="M14 5.99927L6 13.999" stroke="white" strokeWidth="2" />
            <path d="M14 13.9991L6 5.99939" stroke="white" strokeWidth="2" />
        </svg>
    )
}
