import {
    Box,
    Card,
    CardContent,
    CardMedia,
    Grid,
    Stack,
    Typography,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { DumbFunction } from '../../types'
import { THEME } from 'constants/index'

interface IProps {
    img: string
    name: string
    type: string
    count: number
    onClick?: DumbFunction
}

export default function MediaCard({ img, name, type, count, onClick }: IProps) {
    const theme = useTheme()

    return (
        <Card
            sx={{
                backgroundColor: 'grey.50',
                display: 'flex',
                cursor: 'pointer',
                height: '180px',
                padding: '20px',
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '10px',
                position: 'relative',
                '&:hover': {
                    transition: '0.3s',
                    backgroundColor: 'bg.paper',
                    boxShadow: THEME.boxShadow,
                    borderColor: 'grey.900',
                },
            }}
            onClick={onClick}
        >
            <CardMedia
                component="img"
                image={img}
                sx={{
                    width: '54px',
                    height: '54px',
                    borderRadius: '5px',
                    mr: '20px',
                }}
            />
            <CardContent
                sx={{
                    width: '100%',
                    height: '52px',
                    padding: 0,
                    '&:last-child': { padding: 0 },
                }}
            >
                <Grid
                    container
                    sx={{ height: '100%' }}
                    alignItems="flex-start"
                    direction="column"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant="h3">{name}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography
                            sx={{
                                fontSize: '14px',
                                color: 'grey.600',
                            }}
                        >
                            {type}
                        </Typography>
                    </Grid>
                </Grid>
                <Stack
                    direction="row"
                    spacing="5px"
                    sx={{
                        bgcolor: 'bg.paper',
                        border: `1px solid ${theme.palette.grey[300]}`,
                        padding: '7px 10px',
                        borderRadius: '20px',
                        position: 'absolute',
                        bottom: '20px',
                        right: '20px',
                    }}
                >
                    <Box color="grey.600" fontWeight={700} fontSize="12px">
                        연동된 계정
                    </Box>{' '}
                    <Box color="primary.main" fontWeight={700} fontSize="12px">
                        {count}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}
