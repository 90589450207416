import { useTheme } from '@mui/material'

export default function FilterIcon() {
    const theme = useTheme()
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                stroke={theme.palette.grey[700]}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M2.93325 2H21.0667C21.2676 2.07045 21.4497 2.18595 21.5991 2.33765C21.7484 2.48935 21.8611 2.67322 21.9284 2.87518C21.9957 3.07714 22.0159 3.29183 21.9875 3.5028C21.959 3.71377 21.8826 3.91543 21.7642 4.09233L14.7898 11.7642V21.5284L9.21023 17.3437V11.7642L2.23581 4.09233C2.11737 3.91543 2.04099 3.71377 2.01253 3.5028C1.98406 3.29183 2.00426 3.07714 2.07158 2.87518C2.1389 2.67322 2.25155 2.48935 2.40091 2.33765C2.55027 2.18595 2.73236 2.07045 2.93325 2Z" />
            </g>
        </svg>
    )
}
