import { Avatar, Box, Card, CardContent, Typography } from '@mui/material'
import { BizCategory, BizMgrStatus } from 'model'
import {
    CATEGORY_LABEL,
    ROLE_LABEL,
    STATUS_LABEL,
    THEME,
} from 'constants/index'
import MemberChip from './MemberChip'

interface IProps {
    name: string
    image?: string
    category?: BizCategory
    role?: string
    status?: string
    onClick?: (e: any) => void
}

export default function BusinessCard({
    name,
    image,
    category,
    onClick,
    role,
    status,
}: IProps) {
    return (
        <>
            {role && (
                <Box sx={{ position: 'relative' }}>
                    <MemberChip
                        label={
                            status === BizMgrStatus.In
                                ? ROLE_LABEL[role]
                                : STATUS_LABEL[status ?? '']
                        }
                        sx={{
                            position: 'absolute',
                            left: '-15px',
                            top: '-20px',
                        }}
                    />
                </Box>
            )}
            <Card
                onClick={onClick}
                sx={{ cursor: 'pointer', textAlign: 'center', pt: '17px' }}
            >
                <Avatar
                    alt="logo"
                    variant="square"
                    src={image}
                    sx={{
                        width: 54,
                        height: 54,
                        m: '0 auto',
                        borderRadius: THEME.borderRadius,
                        border: '1px solid',
                        borderColor: 'grey.300',
                        bgcolor: image ? 'transparent' : undefined,
                    }}
                />
                <CardContent
                    sx={{
                        padding: '20px 5px',
                        '&:last-child': { paddingBottom: '16px' },
                    }}
                >
                    <Typography
                        gutterBottom
                        variant="h4"
                        component="div"
                        className="ellipsis"
                        sx={{ mb: 0 }}
                    >
                        {name}
                    </Typography>
                    <Typography
                        variant="body1"
                        color="text.secondary"
                        style={{ maxHeight: 37 }}
                    >
                        {category
                            ? CATEGORY_LABEL[category] || category
                            : 'N/A'}
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">Share</Button>*/}
                {/*    <Button size="small">Learn More</Button>*/}
                {/*</CardActions>*/}
            </Card>
        </>
    )
}
