import { useQuery } from 'react-query'
import { AdAccountApi } from 'api/index'
import { AdAccount } from 'model'

interface IParams {
    bizId?: number
    adAccountId?: number
    enabled?: boolean
}

export default function useAdAccount({
    bizId,
    adAccountId,
    enabled = true,
}: IParams) {
    return useQuery<AdAccount>(
        ['ad-account', bizId, adAccountId],
        () => AdAccountApi.getAdAccount(bizId!, adAccountId!),

        {
            suspense: true,
            enabled: !!bizId && !!adAccountId && enabled,
        },
    )
}
