import { useQuery } from 'react-query'
import { BusinessApi } from 'api/index'

export default function useBusiness({ bizId }) {
    return useQuery(
        ['business', bizId],
        () => BusinessApi.getBusiness({ bizId }),
        {
            suspense: true,
            enabled: Boolean(bizId), // There is no business whose `bizId` is 0
        },
    )
}
