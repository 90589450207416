export default {
    get: {
        // 'https://dev.aurora-api.lever.me/businesses/6/adaccounts': [
        //     {
        //         id: 1,
        //         biz_id: 6,
        //         channel: {
        //             category: 'media',
        //             created_at: '2022-03-11T11:05:55.398847+00:00',
        //             logo_url:
        //                 'https://s3-ap-northeast-2.amazonaws.com/aurora.lever.me/channel/kakao_moment/media-kakao.svg',
        //             name: '카카오 모먼트',
        //             type: 'kakao_moment',
        //             updated_at: '2022-03-15T13:36:02.223144+00:00',
        //         },
        //         name: 'xxx',
        //         status: 'standBy',
        //         account_id: 1,
        //         info: {
        //             account_id: '1231412',
        //             client_id: 'blabla',
        //             access_token: 'tokentoken',
        //             refresh_token: 'refreash-token',
        //         },
        //     },
        //     {
        //         id: 2,
        //         biz_id: 6,
        //         channel: {
        //             category: 'media',
        //             created_at: '2022-03-11T11:05:55.398847+00:00',
        //             logo_url:
        //                 'https://s3-ap-northeast-2.amazonaws.com/aurora.lever.me/channel/kakao_moment/media-kakao.svg',
        //             name: '카카오 모먼트',
        //             type: 'kakao_moment',
        //             updated_at: '2022-03-15T13:36:02.223144+00:00',
        //         },
        //         name: 'xxx',
        //         status: 'standBy',
        //         account_id: 2,
        //         info: {
        //             account_id: 'xxxxxx',
        //             client_id: 'cccccc',
        //             access_token: 'aaaaa',
        //             refresh_token: 'bbbbb',
        //         },
        //     },
        //     {
        //         id: 3,
        //         biz_id: 6,
        //         channel: {
        //             category: 'media',
        //             created_at: '2022-03-11T11:22:38.790939+00:00',
        //             logo_url:
        //                 'https://s3-ap-northeast-2.amazonaws.com/aurora.lever.me/channel/moloco/media-moloco.svg',
        //             name: '몰로코',
        //             type: 'moloco',
        //             updated_at: '2022-03-15T13:58:28.310020+00:00',
        //         },
        //         name: 'xxx',
        //         status: 'standBy',
        //         account_id: 3,
        //         info: {
        //             ad_account_id: '1231412',
        //             id: 'blabla',
        //             pw: 'xxxx',
        //             workspace_id: 'mmmm',
        //         },
        //     },
        // ],
    },
    post: {},
    put: {},
    delete: {},
}
