import { useQuery } from 'react-query'
import { AdAccountApi } from 'api/index'
import { ChannelType } from 'model/channel-type'

interface Params {
    bizId?: number
    channel?: ChannelType
    withAccounts?: boolean
}

export default function useAdAccounts<T>({
    bizId,
    channel,
    withAccounts,
}: Params) {
    return useQuery<Array<T>>(
        ['ad-accounts', bizId, channel, withAccounts],
        () => AdAccountApi.getAdAccounts({ bizId, channel, withAccounts }),

        {
            suspense: true,
            enabled: Boolean(bizId), // There is no business whose `bizId` is 0
        },
    )
}
