import { useTheme } from '@mui/material'

export default function ChartIcon() {
    const theme = useTheme()
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                stroke={theme.palette.grey[700]}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M6.44444 10.8889C8.89904 10.8889 10.8889 8.89904 10.8889 6.44444C10.8889 3.98985 8.89904 2 6.44444 2C3.98985 2 2 3.98985 2 6.44444C2 8.89904 3.98985 10.8889 6.44444 10.8889Z" />
                <path d="M6.44434 2V6.44444H10.8888" />
                <path d="M8.66675 17.5555V22" />
                <path d="M17.5557 14.2222V21.9999" />
                <path d="M13.1111 13.1111V22" />
                <path d="M22 12V22" />
            </g>
        </svg>
    )
}
