import { useLocation, useNavigate, useRoutes } from 'react-router-dom'
import MainRoutes from './MainRoutes'
import MinimalRoutes from './MinimalRoutes'
import config from 'config'
import { pathMatched } from '@madup-inc/utils'
import { useEffect } from 'react'
import SubRoutes from './SubRoutes'
import PageNotFound from 'views/pages/PageNotFound'
import token from 'biz/token'

export default function Routes() {
    const privatePage = pathMatched(['/', /\/basic\/.+/])
    const accessToken = token()
    const notAuthorized = privatePage && !accessToken
    const navigate = useNavigate()
    const location = useLocation()

    const Page = useRoutes(
        [MainRoutes, MinimalRoutes, SubRoutes],
        config.basename,
    ) || <PageNotFound />

    useEffect(() => {
        if (notAuthorized) {
            navigate('/login')
        }
    }, [location.pathname])

    return notAuthorized ? null : Page
}
