import colors from 'assets/scss/_themes-vars.module.scss'
import { BizCategory, BizMgrRole, BizMgrStatus, ChannelType } from 'model'

export const ACCESS_TOKEN_NAME = 'access_token'
export const REFRESH_TOKEN_NAME = 'refresh_token'
export const X_REFERER = 'aurora.lever.me'
export const TOKEN_REFRESH_ERROR = 'token refresh error'
export const COOKIE_DOMAIN = '.lever.me'
export const USER_ID_NAME = 'user_id'
export const AUTO_LOGIN_NAME = 'auto_login'
export const Z_INDEX = {
    confirm: 9999,
    loading: 10000,
}
export const IN_SCROLL = { overflow: 'auto', height: 'calc(100vh - 129px)' }

export const THEME = {
    borderRadius: '5px',
    borderRadius2: '10px',
    boxShadow: colors.shadow,
    color: {
        error_main: '#F44336',
        primary_800: '#1565C0',
        primary_main: '#2196F3',
        primary_dot_1: 'rgba(33, 150, 243, 0.1)',
        grey_600: '#757575',
        grey_500: '#9E9E9E',
        grey_300: '#E0E0E0',
    },
}

export const NO_LEVER_USER = 'Not found user in BizLever'

export const CATEGORY_LABEL = {
    [BizCategory.Fashion]: '패션',
    [BizCategory.CosmeticsAndBeauty]: '화장품/미용',
    [BizCategory.HealthAndFood]: '건강/식품',
    [BizCategory.LifeAndGrocery]: '생활/잡화',
    [BizCategory.Service]: '서비스',
    [BizCategory.Finance]: '금융/보험',
    [BizCategory.Other]: '기타',
}

export const ROLE_LABEL = {
    [BizMgrRole.Master]: '마스터',
    [BizMgrRole.Member]: '멤버',
    [BizMgrRole.Viewer]: '뷰어',
}

export const STATUS_LABEL = {
    [BizMgrStatus.Requested]: '요청 대기',
    [BizMgrStatus.Invited]: '초대 대기',
    [BizMgrStatus.RejectedReq]: '요청 거부됨',
    [BizMgrStatus.RejectedInvite]: '초대 거부됨',
    [BizMgrStatus.CancelInvite]: '초대 취소됨',
    [BizMgrStatus.CancelReq]: '요청 취소됨',
}

export const AD_ACCOUNT_DISPLAY_INFOS = {
    [ChannelType.Google]: ['login_customer_id', 'customer_id'],
    [ChannelType.Moloco]: ['ad_account_id', 'email', 'workplace_id'],
    [ChannelType.NaverKeyword]: ['customer_id'],
    [ChannelType.AppleSearch]: ['org_id'],
    [ChannelType.Twitter]: ['account_id'],
    [ChannelType.Tiktok]: ['advertiser_id'],
    [ChannelType.Rtbhouse]: ['email', 'advertiser_hash'],
    [ChannelType.Remerge]: ['email'],
    [ChannelType.Appsflyer]: ['app_id', 'id', 'api_token_v1', 'api_token_v2'],
    [ChannelType.KakaoBrand]: ['account_id'],
    [ChannelType.KakaoMoment]: ['account_id'],
    [ChannelType.KakaoKeyword]: ['account_id'],
    [ChannelType.Criteo]: ['advertiser_ids'],
    [ChannelType.Facebook]: ['account_id'],
    [ChannelType.Mobon]: ['id', 'ad_account_id'],
    [ChannelType.Nosp]: ['account_key'],
}

export const USE_MADUP_ACCOUNT = 'use_madup_account'

export const PERIOD_LABEL = {
    thatMonth: '당월',
    lastWeek: '최근 7일',
    lastThirtyDays: '최근 30일',
}

export const FILE_FORMAT_LABEL = {
    daily: '일자별로 구분',
    total: '전체 합산',
}

export const CRON_EXP_DEFAULT_VALUE = '0 0 * * *'

export const DAY_NAMES = ['월', '화', '수', '목', '금', '토', '일']

export const REPEAT_OPTIONS = [
    {
        id: 'daily',
        name: '매일 업로드',
    },
    {
        id: 'weekly',
        name: '매주 지정된 요일에 업로드',
    },
    {
        id: 'monthly',
        name: '매월 지정된 날짜에 업로드',
    },
]

export const REPEAT_LABEL = {
    daily: '매일',
    weekly: '매주',
    monthly: '매월',
}

export const FILTER_CONDITIONS = [
    { id: 'equal', name: '같음' },
    { id: 'not_equal', name: '같지 않음' },
    { id: 'greater_than', name: '보다 큼' },
    { id: 'greater_than_or_equal_to', name: '크거나 같음' },
    { id: 'less_than', name: '보다 작음' },
    { id: 'less_than_or_equal_to', name: '작거나 같음' },
]
