import { confirm } from '../../../Confirmer'
import { asyncLoading } from 'biz/index'
import { AdAccountApi } from 'api/index'
import toast from 'biz/toast'
import {
    always,
    append,
    filter,
    go,
    identity,
    ifElse,
    oneOf,
    propEq,
    upperFirstChar,
    when,
} from 'utils/lib'
import { BizMgrRole } from 'model/biz-mgr-role'
import { AdAccountStatus } from 'model/ad-account-status'
import { ChannelType } from 'model/channel-type'
import { AD_ACCOUNT_DISPLAY_INFOS } from 'constants/index'

export const popoverMenuOptions = ({
    setVisible,
    adAccount,
    refetch,
    business,
}) =>
    go(
        [
            { label: '수정', onClick: () => setVisible(true) },
            {
                label: '계정 삭제',
                sx: { color: 'error.main' },
                onClick: () =>
                    confirm({
                        title: '광고 계정을 삭제하시겠습니까?',
                        desc: (
                            <div>
                                해당 계정을 삭제하면 더 이상 <br />
                                데이터 수집과 자동화를 사용하실 수 없습니다.
                            </div>
                        ),
                        okLabel: '삭제',

                        onOk: asyncLoading(async close => {
                            await AdAccountApi.deleteAdAccount({
                                bizId: adAccount.biz_id,
                                adAccountId: adAccount.id,
                            })
                            await refetch()
                            toast.success('삭제 완료')
                            close()
                        }),
                        onCancel: close => close(),
                        options: {
                            okSx: {
                                '&.MuiButton-root': {
                                    bgcolor: 'error.main',
                                },
                            },
                        },
                    }),
            },
        ],
        ifElse(
            always(business?.my_role === BizMgrRole.Master),
            identity,
            filter(propEq('label', '수정')),
        ),
    )

export const connectData = ({ bizId, adAccount, refetch }) => {
    confirm({
        title: '데이터 연동을 하시겠습니까?',
        desc: '확인을 누르면 데이터 수집이 시작됩니다.',
        onOk: asyncLoading(async close => {
            await AdAccountApi.changeStatus(bizId!, adAccount.id, {
                before: adAccount.status!,
                after: AdAccountStatus.Active,
            })
            await refetch()
            toast.success('연동 완료')
            close()
        }),
        onCancel: close => close(),
    })
}

export const disConnectData = ({ bizId, adAccount, refetch }) => {
    confirm({
        title: '데이터 연동을 해제하시겠습니까?',
        desc: (
            <div>
                확인을 누르면 더 이상 데이터 수집이 진행되지 않습니다. <br />
                연동 해제 후 다시 데이터 연동이 가능합니다.
            </div>
        ),
        onOk: asyncLoading(async close => {
            await AdAccountApi.changeStatus(bizId!, adAccount.id, {
                before: adAccount.status!,
                after: AdAccountStatus.Stopped,
            })
            await refetch()
            toast.success('연동 해제 완료')
            close()
        }),
        onCancel: close => close(),
        options: {
            okSx: {
                '&.MuiButton-root': {
                    bgcolor: 'error.main',
                },
            },
        },
    })
}

export const moreInfo = (adAccount, item) => {
    if (adAccount.channel === ChannelType.Appsflyer) {
        return oneOf([
            [
                item.label === 'API v1',
                adAccount.config.v1_report_type.map(upperFirstChar).join(', '),
            ],
            [
                item.label === 'API v2',
                adAccount.config.v2_report_type.map(upperFirstChar).join(', '),
            ],
        ])
    }
}

export const predDisplayInfo = adAccount => item => {
    return (
        AD_ACCOUNT_DISPLAY_INFOS[adAccount.channel].includes(item.key) &&
        (adAccount.info![item.key] ||
            item.value ||
            (isAppsMadupAccount(adAccount) && 'api_token_v1' === item.key))
    )
}

export const addApiV1ItemWhenAppsMadup = adAccount =>
    when(
        always(isAppsMadupAccount(adAccount)),
        append({
            label: 'API v1',
            key: 'api_token_v1',
            type: 'input',
            placeholder: 'API v1값을 입력해 주세요.',
        }),
    )

export const isAppsMadupAccount = adAccount =>
    adAccount.channel === ChannelType.Appsflyer &&
    adAccount.config?.['use_madup_account']
