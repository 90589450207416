import { downloadData, omitProto } from 'utils/lib'
import { IPlainObject } from 'types/index'
import request from './request'
import { tokenRefresh } from '@madup-inc/common-biz'
import { TOKEN_REFRESH_ERROR, X_REFERER } from '../constants/index'

export default async function req(path: string, option: IPlainObject) {
    const $logger = window.$logger.addTags('req')
    const label = `[${option.method}] ` + path
    const { withToken = true } = option
    const timeLogger = $logger.if(process.env.REACT_APP_LOG_API_TIME === 'true')
    timeLogger.verbose.time(label)
    try {
        const headers = {
            Pragma: 'no-cache',
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache, no-store',
        }
        const accessToken = await tokenRefresh({
            authUrl: process.env.REACT_APP_AUTH_API_URL || '', // 환경에 따른 인증서버 주소 설정
            xReferer: X_REFERER, // 프로젝트의 xReferer 값을 설정
            // logger: msg => window.$logger.verbose(msg),
            tokenRefreshError: TOKEN_REFRESH_ERROR,
            stringify: false,
        })
        if (withToken && accessToken) {
            headers['access-token'] = accessToken
        }
        return await request({
            path,
            option: { timeout: 10000, ...option },
            headers,
        })
    } catch (err) {
        $logger.error(
            label,
            omitProto({
                input: option.method === 'get' ? option.params : option.data,
                output: err.response,
            }),
        )
        throw err
    } finally {
        timeLogger.verbose.timeEnd(label)
    }
}

const get = (path, params?: IPlainObject, option: IPlainObject = {}) =>
    req(path, { method: 'get', params, ...option })
get.download = (path, params, option: IPlainObject = {}) =>
    req(path, {
        method: 'get',
        responseType: 'blob',
        params,
        ...option,
    }).then(res => downloadData(res, option.filename))
req.get = get

const post = (path, data?: IPlainObject, option: IPlainObject = {}) =>
    req(path, { method: 'post', data, ...option })
post.download = (path, data, option: IPlainObject = {}) =>
    req(path, {
        method: 'post',
        responseType: 'blob',
        data,
        ...option,
    }).then(res => downloadData(res, option.filename))
req.post = post

req.put = (path, data) => req(path, { method: 'put', data })
req.delete = (path, data?: IPlainObject) =>
    req(path, { method: 'delete', data })
