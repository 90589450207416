import { Checkbox as MuiCheckBox, CheckboxProps } from '@mui/material'
import CheckboxChecked from './svgs/CheckboxChecked'
import CheckboxIcon from './svgs/CheckboxIcon'

interface IProps extends CheckboxProps {
    error?: boolean
}

export default function Checkbox({ error, ...props }: IProps) {
    return (
        <MuiCheckBox
            {...props}
            sx={{
                padding: 0,
                '&.Mui-checked': { color: 'primary.800' },
                '&.Mui-disabled': { color: 'grey.500' },
                ...props.sx,
            }}
            icon={<CheckboxIcon disabled={props.disabled} />}
            checkedIcon={
                <CheckboxChecked error={error} disabled={props.disabled} />
            }
        />
    )
}
