import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from 'ui-component/Dialog'
import Button from 'ui-component/Button'
import AddAccountsForm from './AdAccountsForm'
import useChannels from 'queries/useChannels'
import { propEq } from 'utils/lib'
import { Typography } from '@mui/material'
import React, { useState } from 'react'
import {
    AdAccountConfig,
    AdAccountInfo,
    AnyOutputFunctionPromise,
    DumbFunction,
} from '../../../types'
import { asyncLoading, inputsFilled } from 'biz/index'
import { AdAccountApi } from 'api/index'
import useBizId from 'zustands/useBizId'
import toast from 'biz/toast'
import { ChannelType } from 'model/channel-type'
import useAdAccount from 'queries/useAdAccount'
import { AdminAccount } from 'model/admin-account'
import { USE_MADUP_ACCOUNT } from 'constants/index'
import { matched } from '@madup-inc/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import useBusiness from 'queries/useBusiness'
import { BizMgrRole } from 'model/biz-mgr-role'
import { CollectType } from 'model/collect-type'

interface IProps {
    open: boolean
    onClose: DumbFunction
    type: ChannelType | undefined
    adAccountId?: number
    editMode?: boolean
    refetch?: AnyOutputFunctionPromise
}

export default function DialogAdAccountForm({
    open,
    onClose,
    type,
    adAccountId,
    editMode,
    refetch,
}: IProps) {
    const { bizId } = useBizId()
    const navigate = useNavigate()
    const { data: channels } = useChannels()
    const location = useLocation()
    const { data: business } = useBusiness({ bizId })
    const { data: adAccount } = useAdAccount({ bizId, adAccountId })

    const configDefault = adAccount
        ? adAccount.config!
        : ({} as AdAccountConfig)
    const infoDefault = adAccount ? adAccount.info! : ({} as any)
    const [config, setConfig] = useState<AdAccountConfig>(configDefault)
    const [info, setInfo] = useState<AdAccountInfo>(infoDefault)

    if (!type) {
        return null
    }

    const googleYesYesNo =
        type === ChannelType.Google &&
        config[USE_MADUP_ACCOUNT] &&
        config['use_madup_mcc'] &&
        !config['is_admin_account']

    const channel = channels!.find(propEq('type', type))
    const handleClose = () => {
        setConfig(configDefault)
        setInfo(infoDefault)
        onClose()
    }

    const passed =
        inputsFilled(type, config, info) &&
        business?.my_role !== BizMgrRole.Viewer
    const desc =
        `${channel?.name} 광고 계정을 ${editMode ? '수정' : '추가'}합니다.` +
        (matched([ChannelType.Remerge, ChannelType.Rtbhouse], channel?.type)
            ? ''
            : `정보 입력 시 하단에 <정보 입력 가이드> 를 참고해 주세요.`)

    const onClick = asyncLoading(
        editMode
            ? editAdAccount({
                  bizId,
                  adAccountId,
                  adAccount,
                  type,
                  info,
                  config,
                  refetch,
                  onClose,
              })
            : addAdAccount({
                  bizId,
                  type,
                  info,
                  googleYesYesNo,
                  config,
                  refetch,
                  handleClose,
                  navigate,
                  location,
              }),
    )
    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
            <DialogTitle>
                <div>
                    <Typography variant="h2">
                        광고 계정 {editMode ? '수정' : '추가'}
                    </Typography>
                    <Typography
                        sx={{
                            mt: '20px',
                            lineHeight: '21px',
                            color: 'grey.700',
                        }}
                    >
                        {desc}
                    </Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <AddAccountsForm
                    type={type}
                    info={info}
                    setInfo={setInfo}
                    config={config}
                    setConfig={setConfig}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size="large" variant="outlined">
                    취소
                </Button>
                <Button
                    onClick={onClick}
                    variant="contained"
                    size="large"
                    disabled={!passed}
                >
                    {editMode ? '수정하기' : '추가하기'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const addAdAccount =
    ({
        bizId,
        type,
        info,
        googleYesYesNo,
        config,
        refetch,
        handleClose,
        navigate,
        location,
    }) =>
    async () => {
        await AdAccountApi.addAdAccount(bizId!, {
            channel: type,
            info: {
                ...info,
                ...(googleYesYesNo && {
                    customer_id: info['login_customer_id'],
                }),
                ...(type === ChannelType.Mobon && {
                    collect_type: CollectType.Scrapy,
                }),
            },
            config: {
                ...(config[USE_MADUP_ACCOUNT] && {
                    madup_account: AdminAccount.PonpleAd,
                }),
                ...config,
            },
        })

        await refetch?.()
        toast.success('계정정보 추가 완료')
        if (location.pathname === '/basic/channels') {
            handleClose()
        } else {
            navigate('/basic/channels')
        }
    }

export const editAdAccount =
    ({ bizId, adAccountId, adAccount, type, info, config, refetch, onClose }) =>
    async () => {
        await AdAccountApi.updateAdAccount(bizId!, adAccountId!, {
            channel: type,
            info,
            config,
            status: adAccount!.status,
        })
        await refetch?.()
        toast.success('계정정보 수정 완료')
        onClose()
    }
