export default function CheckboxIcon({ disabled }) {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="19"
                height="19"
                rx="1.5"
                stroke={disabled ? '#9E9E9E' : '#616161'}
            />
        </svg>
    )
}
