import { useTheme } from '@mui/material'

export default function FilterIcon() {
    const theme = useTheme()
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                stroke={theme.palette.grey[700]}
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path d="M6.48813 9.78354H9.78354V6.48813L5.93889 2.64348C7.16877 2.05609 8.55051 1.86445 9.89383 2.09492C11.2371 2.3254 12.476 2.96667 13.4397 3.93042C14.4035 4.89416 15.0447 6.13299 15.2752 7.47631C15.5057 8.81963 15.314 10.2014 14.7267 11.4313L21.3175 18.0221C21.7545 18.4591 22 19.0518 22 19.6698C22 20.2878 21.7545 20.8805 21.3175 21.3175C20.8805 21.7545 20.2878 22 19.6698 22C19.0518 22 18.4591 21.7545 18.0221 21.3175L11.4313 14.7267C10.2014 15.314 8.81963 15.5057 7.47631 15.2752C6.13299 15.0447 4.89416 14.4035 3.93042 13.4397C2.96667 12.476 2.3254 11.2371 2.09492 9.89383C1.86445 8.55051 2.05609 7.16877 2.64348 5.93889L6.48813 9.78354Z" />
            </g>
        </svg>
    )
}
