import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material'
import Breadcrumbs from 'ui-component/extended/Breadcrumbs'
import Header from './Header'
import Sidebar from './Sidebar'
import navigation from 'menu-items'
import { drawerWidth } from 'store/constant'
import { SET_MENU } from 'store/actions'
import { IconChevronRight } from '@tabler/icons'
import useBizId from 'zustands/useBizId'

const Main: any = styled('main', {
    shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }: any) => ({
    ...theme.typography.mainContent,
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(drawerWidth - 20),
            width: `calc(100% - ${drawerWidth}px)`,
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
            width: `calc(100% - ${drawerWidth}px)`,
            padding: '16px',
            marginRight: '10px',
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: `calc(100% - ${drawerWidth}px)`,
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '10px',
        },
    }),
}))

export default function MainLayout() {
    const theme = useTheme()
    const matchDownMd = useMediaQuery(theme.breakpoints.down('lg'))

    const leftDrawerOpened = useSelector(
        (state: any) => state.customization.opened,
    )
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened })
    }

    const { bizId } = useBizId()

    useEffect(() => {
        dispatch({ type: SET_MENU, opened: !matchDownMd })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matchDownMd])

    useEffect(() => {
        if (!bizId) {
            navigate('/basic/businesses')
        }
    }, [])

    if (!bizId) {
        return null
    }

    // @ts-ignore
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened
                        ? theme.transitions.create('width')
                        : 'none',
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            <Sidebar
                drawerOpen={leftDrawerOpened}
                drawerToggle={handleLeftDrawerToggle}
            />

            <Main theme={theme} open={leftDrawerOpened}>
                <Breadcrumbs
                    separator={IconChevronRight}
                    navigation={navigation}
                    icon
                    title
                    rightAlign
                />
                <Outlet />
            </Main>
        </Box>
    )
}
