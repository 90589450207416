import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
} from '@tabler/icons'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined'

const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
}

const designGuide = {
    id: 'design-guide',
    title: 'Design guide',
    caption: '표준 폰트 크기 및 칼라',
    type: 'group',
    children: [
        {
            id: 'util-typography',
            title: 'Typography',
            type: 'item',
            url: '/design-guide/util-typography',
            icon: icons.IconTypography,
            breadcrumbs: false,
        },
        {
            id: 'util-breakpoint',
            title: 'Breakpoint',
            type: 'item',
            url: '/design-guide/util-breakpoint',
            icon: PhoneIphoneIcon,
            breadcrumbs: false,
        },
        {
            id: 'util-color',
            title: 'Color',
            type: 'item',
            url: '/design-guide/util-color',
            icon: icons.IconPalette,
            breadcrumbs: false,
        },
        {
            id: 'util-shadow',
            title: 'Shadow',
            type: 'item',
            url: '/design-guide/util-shadow',
            icon: icons.IconShadow,
            breadcrumbs: false,
        },
        {
            id: 'icons',
            title: 'Icons',
            type: 'collapse',
            icon: icons.IconWindmill,
            children: [
                {
                    id: 'tabler-icons',
                    title: 'Tabler Icons',
                    type: 'item',
                    url: '/icons/tabler-icons',
                    breadcrumbs: false,
                },
                {
                    id: 'material-icons',
                    title: 'Material Icons',
                    type: 'item',
                    url: '/icons/material-icons',
                    breadcrumbs: false,
                },
            ],
        },
        {
            id: 'components',
            title: 'Components',
            type: 'collapse',
            icon: AccountTreeOutlinedIcon,
            children: [
                {
                    id: 'button',
                    title: 'Button',
                    type: 'item',
                    url: '/design-guide/components/button-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'box-list',
                    title: 'BoxList',
                    type: 'item',
                    url: '/design-guide/components/box-list-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'checkbox-group',
                    title: 'CheckboxGroup',
                    type: 'item',
                    url: '/design-guide/components/checkbox-group-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'collapse',
                    title: 'Collapse',
                    type: 'item',
                    url: '/design-guide/components/collapse-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'dialog',
                    title: 'Dialog',
                    type: 'item',
                    url: '/design-guide/components/dialog-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'date-picker',
                    title: 'DatePicker',
                    type: 'item',
                    url: '/design-guide/components/date-picker-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'date-range-picker',
                    title: 'DateRangePicker',
                    type: 'item',
                    url: '/design-guide/components/date-range-picker-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'multiSelect',
                    title: 'MultiSelect',
                    type: 'item',
                    url: '/design-guide/components/multi-select-doc',
                    breadcrumbs: false,
                },

                {
                    id: 'popover-menu',
                    title: 'PopoverMenu',
                    type: 'item',
                    url: '/design-guide/components/popover-menu-doc',
                    breadcrumbs: false,
                },

                {
                    id: 'radio',
                    title: 'Radio',
                    type: 'item',
                    url: '/design-guide/components/radio-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'select',
                    title: 'Select',
                    type: 'item',
                    url: '/design-guide/components/select-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'svgIcons',
                    title: 'SvgIcons',
                    type: 'item',
                    url: '/design-guide/components/svg-icons-doc',
                    breadcrumbs: false,
                },
                {
                    id: 'table',
                    title: 'Table',
                    type: 'item',
                    url: '/design-guide/components/table-doc',
                    breadcrumbs: false,
                },
            ],
        },
    ],
}

export default designGuide
