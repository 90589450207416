import { lazy } from 'react'
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'
import PageNotFound from 'views/pages/PageNotFound'

const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')))
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login')))
const AuthRegister3 = Loadable(
    lazy(() => import('views/pages/authentication/authentication3/Register3')),
)

const MinimalRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/pages/login/login3',
            element: <AuthLogin3 />,
        },
        {
            path: '/login',
            element: <AuthLogin />,
        },
        {
            path: '/pages/register/register3',
            element: <AuthRegister3 />,
        },
        {
            path: '/404',
            element: <PageNotFound />,
        },
    ],
}

export default MinimalRoutes
