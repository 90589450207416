import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import List from '@mui/material/List'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import AD_ACCOUNTS_FORM from 'constants/accountForm'
import { THEME } from 'constants/index'
import UnstyledSwitch from 'ui-component/extended/UnstyledSwitch'
import { ReactComponent as MoreHover } from 'assets/images/icons/more-hover.svg'
import PopoverMenu from 'ui-component/PopoverMenu'
import { MouseEvent, useState } from 'react'
import toast from 'biz/toast'
import DialogAdAccountForm from './DialogAdAccountForm'
import { AdAccount } from 'model/ad-account'
import useBizId from 'zustands/useBizId'
import { AdAccountStatus } from 'model/ad-account-status'
import { getItems } from 'biz/index'
import AdAccountListItem from './AdAccountListItem'
import { BizMgrRole } from 'model/biz-mgr-role'
import useBusiness from 'queries/useBusiness'
import {
    connectData,
    disConnectData,
    moreInfo,
    popoverMenuOptions,
    addApiV1ItemWhenAppsMadup,
    predDisplayInfo,
} from './CardAdAccount.fn'

interface IProps {
    adAccount: AdAccount
    refetch: any
}

export default function CardAdAccount({ adAccount, refetch }: IProps) {
    const { bizId } = useBizId()
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const forms = AD_ACCOUNTS_FORM[adAccount.channel]
    const itemList = getItems(forms, adAccount.config)
    const [visible, setVisible] = useState(false)
    const { data: business } = useBusiness({ bizId })

    const handleClick = (event: MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <Card
            variant="outlined"
            sx={{
                '&.MuiPaper-root': {
                    borderRadius: THEME.borderRadius,
                    borderColor: 'grey.300',
                },
            }}
        >
            <CardHeader
                sx={{ padding: '23px 20px' }}
                title={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography
                            variant="body2"
                            color="grey.600"
                            fontWeight="700"
                        >
                            데이터 연동하기
                        </Typography>
                        <UnstyledSwitch
                            checked={
                                adAccount.status === AdAccountStatus.Active
                            }
                            onChange={e => {
                                if (business?.my_role === BizMgrRole.Viewer) {
                                    toast.error(
                                        '데이터 연동 및 해제는 멤버 이상의 권한이 필요합니다.',
                                    )
                                    return
                                }
                                if (e.target.checked) {
                                    connectData({
                                        bizId,
                                        adAccount,
                                        refetch,
                                    })
                                } else {
                                    disConnectData({
                                        bizId,
                                        adAccount,
                                        refetch,
                                    })
                                }
                            }}
                        />
                    </Stack>
                }
            />
            <CardContent
                sx={{
                    '&.MuiCardContent-root': {
                        padding: '16px 20px',
                        bgcolor: 'grey.50',
                    },
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <List
                        sx={{ flex: 1, padding: 0, '> li': { padding: '0' } }}
                    >
                        {Array.isArray(itemList)
                            ? addApiV1ItemWhenAppsMadup(adAccount)(itemList)
                                  .filter(predDisplayInfo(adAccount))
                                  .map(item => (
                                      <AdAccountListItem
                                          key={item.key}
                                          label={item.label}
                                          value={
                                              moreInfo(adAccount, item) ||
                                              adAccount.info![item.key] ||
                                              item.value
                                          }
                                      />
                                  ))
                            : 'This is unavailable ad account. delete this ad account!'}
                    </List>
                    <div
                        onClick={handleClick}
                        style={{ cursor: 'pointer', marginLeft: '32px' }}
                    >
                        <MoreHover />
                    </div>
                </Stack>
                <PopoverMenu
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    list={popoverMenuOptions({
                        setVisible,
                        adAccount,
                        refetch,
                        business,
                    })}
                />
                <DialogAdAccountForm
                    open={visible}
                    onClose={() => setVisible(false)}
                    type={adAccount.channel}
                    adAccountId={adAccount.id}
                    refetch={refetch}
                    editMode
                />
            </CardContent>
        </Card>
    )
}
