import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import logger from 'utils/logger'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { store } from 'store'
import 'assets/scss/style.scss'

window.$logger = logger

logger.info('* ENV_TYPE: ' + process.env.REACT_APP_ENV_TYPE)
logger.info(`* COMMIT_HASH_DATE: ` + process.env.COMMIT_HASH_DATE)
logger.info('* LOG_LEVEL: ' + logger.options.level)
logger.info('* PATH_LIST: ' + process.env.PATH_LIST)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
