import {
    CRON_EXP_DEFAULT_VALUE,
    DAY_NAMES,
    FILE_FORMAT_LABEL,
    PERIOD_LABEL,
    REPEAT_LABEL,
} from 'constants/index'
import { Auth } from 'api/index'
import { setLoading } from 'react-hot-loading'
import { confirm } from '../Confirmer'
import handleError from 'biz/handle-error'
import toast from 'biz/toast'
import dayjs from 'dayjs'
import {
    always,
    gtelte,
    ifElse,
    isEmpty,
    isNil,
    isNotNil,
    lStorage,
    map,
    oneOf,
    pipe,
    prop,
    propEq,
    serializeSearchParams,
    sStorage,
    update,
} from 'utils/lib'
import AD_ACCOUNTS_FORM from 'constants/accountForm'
import { BizMgrStatus } from 'model/biz-mgr-status'
import { BizMgrRole } from 'model/biz-mgr-role'
import { matched } from '@madup-inc/utils'

export const statusToScore = (status: BizMgrStatus): number =>
    oneOf([
        [status === BizMgrStatus.Invited, 10],
        [status === BizMgrStatus.Requested, 5],
    ]) || 0

interface SignUp {
    id_token?: string
    code?: string
    temp_token?: string
}

export const signUp = async ({ id_token, code, temp_token }: SignUp) => {
    await Auth.signUp({
        id_token,
        code,
        temp_token,
    })
    toast.success('오로라 회원가입 성공')
    confirm({
        title: '회원가입이 완료되었습니다',
        desc: '다시 로그인해 주세요',
        cancelButtonHidden: true,
    })
}

export function asyncLoading(fn) {
    return async (...args) => {
        try {
            setLoading(true)
            const result = await fn(...args)
            setLoading(false)
            return result
        } catch (err) {
            setLoading(false)
            handleError(err)
        }
    }
}

export const platformDefault = type => ({
    url: '',
    type,
    icon_url: '',
    app_id: '',
    title: '',
})

export const channelCategoryLabel = category =>
    category === 'media' ? 'Media' : '3rd Party Tracker'

export const writeClipboard = (text: string, message?: string): Promise<void> =>
    window.navigator.clipboard
        .writeText(text)
        .then(() => toast.success(message || 'copied on clipboard'))

export const readClipboard = (): Promise<string> =>
    window.navigator.clipboard.readText()

export const apiErrorReport = error => {
    const message = error.message
    const errConfig = error.response?.config || error.config
    if (!errConfig) {
        return
    }
    const resData = error.response?.data.detail || error.response?.data
    const reqData =
        oneOf([
            [
                errConfig.method === 'get',
                () => serializeSearchParams(errConfig.params),
            ],
            [
                typeof errConfig.data === 'string',
                () => JSON.stringify(JSON.parse(errConfig.data), null, 4),
            ],
        ]) ?? 'N/A (no json type)'

    return `${dayjs().format('YYYY/MM/DD dd HH:mm:ss')}    
    
${errConfig.method.toUpperCase()} ${errConfig.url}

${message}

                     
== access-token ==
${errConfig.headers['access-token']}


== ${errConfig.method === 'get' ? 'query-parameter' : 'req-payload'} ==
${reqData}


== res-data ==
${typeof resData === 'string' ? resData : JSON.stringify(resData, null, 4)}
`
}

export const getItems = (form, config) => {
    if (!form) {
        throw Error('Next form is undefined')
    }

    if (!matched(['radio', 'checkbox'], form.type)) {
        return form.flatMap(item => {
            if (matched(['input', 'file'], item.type)) {
                return item
            }
            if (item.type === 'dynamic') {
                return getDynamicInputList(item, config)
            }
            return []
        })
    }
    if (isNil(config?.[form.key])) {
        return
    }

    const nextForm = form.always || form[config[form.key] ? 'yes' : 'no']

    return getItems(nextForm, config)
}

export const inputsFilled = (type, config, info) => {
    const inputs = getItems(AD_ACCOUNTS_FORM[type], config)
    if (!inputs) {
        return false
    }
    return (
        !isEmpty(inputs) &&
        inputs.every(item => item.disabled || info[item.key])
    )
}

export const nextExpandedList = ({ expandedSingleOnly, expandedList, idx }) =>
    ifElse(
        always(expandedSingleOnly),
        ifElse(
            always(expandedList[idx]),
            map(always(false)),
            pipe(map(always(false)), update(idx, true)),
        ),
        update(idx, !expandedList[idx]),
    )

export const renderMoreIcon = ({ role, user, row }) =>
    (role === BizMgrRole.Master || user?.email === row.mgr.email) &&
    row.role !== BizMgrRole.Master

interface CronParam {
    repeat?: string
    days?: number[]
    dates?: number[]
    hour?: number
    minute?: number
    cron?: string
}

export const buildCron = (param: CronParam) => {
    const { repeat, days, dates, hour, minute, cron } = param
    if (repeat) {
        return oneOf(
            [
                [repeat === 'daily', CRON_EXP_DEFAULT_VALUE],
                [repeat === 'weekly', '0 0 * * 1'],
            ],
            '0 0 1 * *',
        )
    }

    if (!cron) {
        throw Error('`cron` is required')
    }

    const [cron1, cron2, cron3, cron4, cron5] = cron.split(' ')

    return oneOf(
        [
            [
                Boolean(days),
                () =>
                    `${cron1} ${cron2} ${cron3} ${cron4} ${
                        days!.map(item => String(item % 7)).join(',') || 0
                    }`,
            ],
            [
                Boolean(dates),
                () =>
                    `${cron1} ${cron2} ${dates!
                        .map(String)
                        .join(',')} ${cron4} ${cron5}`,
            ],
            [
                Boolean(hour),
                () => `${cron1} ${hour} ${cron3} ${cron4} ${cron5}`,
            ],
            [
                Boolean(minute),
                () => `${minute} ${cron2} ${cron3} ${cron4} ${cron5}`,
            ],
        ],
        () => {
            throw Error('Invalid param')
        },
    )
}

export const parseCron = (cron: string) => {
    const [cron1, cron2, cron3, cron4, cron5] = cron.split(' ')
    return {
        repeat: oneOf([
            [cron3 === '*' && cron4 === '*' && cron5 === '*', 'daily'],
            [cron3 === '*' && cron4 === '*', 'weekly'],
            [cron4 === '*' && cron5 === '*', 'monthly'],
        ]),
        days: cron5
            .split(',')
            .map(id => (Number(id) + 6) % 7)
            .map(id => ({
                id,
                name: DAY_NAMES[id] + '요일',
            })),
        dates: cron3
            .split(',')
            .map(Number)
            .map(id => ({
                id,
                name: dateLabel(id),
            })),
        hour: Number(cron2),
        minute: Number(cron1),
    }
}

export const dateLabel = (date: number) => (date === 31 ? '말일' : date + '일')

export const cronLabel = (cron: string) => {
    const { repeat, days, dates, hour, minute } = parseCron(cron)
    if (repeat === 'daily') {
        return REPEAT_LABEL[repeat] + ' ' + hour + '시 ' + minute + '분 업로드'
    } else if (repeat === 'weekly') {
        return (
            REPEAT_LABEL[repeat] +
            ' ' +
            days.map(prop('name')).join(',') +
            ' ' +
            hour +
            '시 ' +
            minute +
            '분 업로드'
        )
    } else if (repeat === 'monthly') {
        return (
            REPEAT_LABEL[repeat] +
            ' ' +
            dates.map(prop('name')).join(',') +
            ' ' +
            hour +
            '시 ' +
            minute +
            '분 업로드'
        )
    }
}

export const toDate = date =>
    typeof date === 'string' ? dayjs(date).toDate() : date

export const beforeConnected = (date, connected: string) =>
    dayjs(date).unix() < dayjs(connected, 'YYYYMMDD').unix()

export const isFuture = date => dayjs().unix() < dayjs(date).unix()

export const dateCollected = (connected: string) => {
    if (!/(20\d{2})(\d{2})(\d{2})/.test(connected)) {
        throw Error('Invalid date format')
    }
    const start = dayjs(connected, 'YYYYMMDD').unix()
    const end = dayjs().unix()
    return (date: Date) => gtelte(start, dayjs(date).unix(), end)
}

export const getDynamicInputList = (item, config) => {
    if (item.type !== 'dynamic') {
        throw Error('Item type is not `dynamic: `' + item.type)
    }
    return item.options.flatMap(option =>
        option.pred(config) ? option.list : [],
    )
}

export const clearStorage = () => {
    sStorage.clear()
    lStorage.clear()
}

export const typeDropbox = propEq('type', 'dropbox')

export const chipList = ({
    step,
    channel,
    isOnce,
    period,
    dates,
    columns,
    methods,
    fileFormat,
    reservedAt,
    cronExp,
}) => {
    const datesStr =
        dayjs(dates![0]).format('YYYY/MM/DD') +
        ' ~ ' +
        (dates![1] ? dayjs(dates![1]).format('YYYY/MM/DD') : '')

    return (
        oneOf([
            [step === 0, [channel]],
            [step === 1 && !isOnce, [PERIOD_LABEL[period!]]],
            [step === 1 && Boolean(isOnce), [datesStr]],
            [step === 2, columns],
            [
                step === 4,
                [
                    ...methods
                        .filter(item => Boolean(item.url))
                        .map(prop('type')),
                    fileFormat ? FILE_FORMAT_LABEL[fileFormat] : undefined,
                    isOnce && reservedAt
                        ? dayjs(reservedAt).format('YYYY.MM.DD HH') +
                          '시 업로드 예약'
                        : undefined,
                    !isOnce && cronExp && !isEmpty(methods)
                        ? cronLabel(cronExp)
                        : undefined,
                ].filter(isNotNil),
            ],
        ]) || []
    )
}
