import {
    Dialog as MUIDialog,
    DialogTitle as MUIDialogTitle,
    DialogContent as MUIDialogContent,
    DialogActions as MUIDialogActions,
} from '@mui/material'
import { useTheme } from '@mui/system'
import { THEME } from 'constants/index'

export function Dialog({
    open,
    backdropProps = {},
    backdropSX = {},
    ...props
}) {
    return (
        <MUIDialog
            {...props}
            open={open}
            BackdropProps={{
                sx: {
                    backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    ...backdropSX,
                },
                ...backdropProps,
            }}
            sx={{
                '.MuiDialog-container > .MuiPaper-root': {
                    maxWidth: '660px',
                    boxShadow: 'none',
                    borderRadius: THEME.borderRadius2,
                },
                ...props.sx,
            }}
        />
    )
}

export function DialogTitle(props) {
    return (
        <MUIDialogTitle
            {...props}
            sx={{ padding: '50px 40px 20px 40px', ...props.sx }}
        />
    )
}

export function DialogContent(props) {
    return (
        <MUIDialogContent
            {...props}
            sx={{ padding: '40px 40px 40px', ...props.sx }}
        />
    )
}

export function DialogActions(props) {
    const theme = useTheme()

    return (
        <MUIDialogActions
            {...props}
            sx={{
                padding: '20px',
                borderTop: `1px solid ${theme.palette.grey[300]}`,
                '&>:not(:first-of-type)': {
                    ml: '20px',
                },
                '&>.MuiButton-root': {
                    py: '22px',
                    fontSize: '16px',
                },
                ...props.sx,
            }}
        />
    )
}
